import { Input, Icon, Button, DatePicker, Popover } from 'antd';
import React from 'react';
import giftcard from '../../assets/img/book_line.png';
import { CartItem } from '../../interfaces/cart';
import moment, { Moment } from 'moment';
import PlusIcon from '../Icons/PlusIcon';
import Thrash from '../Icons/Thrash';

interface Props {
  item: CartItem;
  onChangeItem: (item: CartItem) => void;
  onRemoveItem: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  itemIndex: number;
  addGiftCard: Function;
}

const GiftCard: React.FC<Props> = ({ item, onChangeItem, onRemoveItem, itemIndex ,addGiftCard }) => {
  
  const cartDividerStyle = itemIndex > 0 ? "cart-divider" : "";
  return (
  <div className={`row cart-item ${cartDividerStyle}`}>
    <div className='col-md-5 col-4'>
      <img src={giftcard} alt='Gift Card' className='w-100' />
    </div>
    <div className='col-md-7 col-8'>
      <div className='row justify-content-start align-items-baseline'>
        <div className='col-10'>
          <h3 className='m-0 cart-item-title'>
            <strong>{item.title}</strong>
          </h3>
        </div>
      </div>
      <div className='row justify-content-start align-items-center my-1'>
        <div className='col-12'>
          <Input
            className='w-100'
            prefix='$'
            type='number'
            value={item.total}
            min={10}
            max={300}
            step={10}
            onChange={e =>
              onChangeItem({ ...item, total: parseInt(e.target.value) || 10 })
            }
            onBlur={() => {
              if (item.total < 10) {
                onChangeItem({ ...item, total: 10 });
              } else if (item.total > 300) {
                onChangeItem({ ...item, total: 300 });
              }
            }}
          />
        </div>
      </div>

      <label>Gift Recipient Name</label>
      <Input
        className='w-100 my-1'
        value={item.gift.receiver}
        onChange={e =>
          onChangeItem({
            ...item,
            gift: { ...item.gift, receiver: e.target.value },
          })
        }
      />

      <label>Gift Recipient Email</label>
      <Input
        className='w-100 my-1'
        type='email'
        value={item.gift.email}
        onChange={e =>
          onChangeItem({
            ...item,
            gift: { ...item.gift, email: e.target.value },
          })
        }
      />

      <label>Personal Message to the Gift Recipient</label>
      <Input
        className='w-100 my-1'
        value={item.gift.message || ''}
        onChange={e =>
          onChangeItem({
            ...item,
            gift: { ...item.gift, message: e.target.value },
          })
        }
        maxLength={45}
      />

      <label>Schedule this Gift Card</label>
      <DatePicker
        format={"MM-DD-YYYY"}
        value={!!item.gift.date ? moment(item.gift.date) : null}
        className="w-100"
        disabledDate={(current: Moment | null) =>
          (current !== null && current > moment().add(1, 'years').endOf("day")) || 
          (current !== null && current < moment().startOf("day"))
        }
        onChange={momentDate => {
          onChangeItem({
            ...item,
            gift: { ...item.gift, date: momentDate?.startOf('day').toString()},
          })
        }}
      />
      <div className="d-flex justify-content-between my-2 giftcard-price">
          <p>Subtotal: </p>
          <p>${item.total.toFixed(2)}</p>
      </div>

      <div className="row justify-content-start my-2 cart-giftcard-actions-margin">
        <span className="mr-1"> <Thrash /> </span>
        <p className="cart-book-actions mr-2" onClick={onRemoveItem}> Remove </p>

        <span className="pl-2 mr-1 book-item-border-left"><PlusIcon /> </span>
        <p className="cart-book-actions" onClick={() => {
          addGiftCard();
        }} >Gift Card</p>
     </div>
    </div>
  </div>
);
}

export default GiftCard;
