import { Checkbox, DatePicker, Icon, Input, Modal } from "antd";
import arrayMove from "array-move";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useLocation } from "react-router";
import queryString from "query-string";
import { Milestone, SimplifiedAnswer } from "../../interfaces";
import { capitalizeText } from "../../utils/string";
import AnswerOrderable from "../AnswerOrderable/AnswerOrderable";
import "./ContentEditAnswers.scss"
import moment from "moment";

interface Props {
  visible: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onOk: (
    title?: string,
    subTitle?: string,
    answers?: SimplifiedAnswer[]
  ) => void;
  title: string;
  subTitle: string;
  answers: SimplifiedAnswer[];
  setSortedAnswers: (orderedAnswers: SimplifiedAnswer[]) => void;
  addCustomAnswer: (answer: string) => void;
  removeAnswer: (index: number) => void;
  isBirthAnnoMilestone: boolean;
  milestoneType?: Milestone;
  setMilestoneDate?: React.Dispatch<React.SetStateAction<string>>;
  milestoneDate?: string;
  setMilestoneAsFirstLine?: React.Dispatch<React.SetStateAction<boolean>>;
  milestoneAsFirstLine?: boolean;
}

const ContentEditAnswers: React.FC<Props> = ({
  visible,
  onCancel,
  onOk,
  title,
  subTitle,
  answers,
  setSortedAnswers,
  addCustomAnswer,
  removeAnswer,
  isBirthAnnoMilestone,
  milestoneType,
  setMilestoneDate,
  milestoneDate,
  setMilestoneAsFirstLine,
  milestoneAsFirstLine
}) => {
  const [editTitle, setEditTitle] = useState(title);
  const [editSubTitle, setEditSubTitle] = useState(subTitle);
  const [editAnswers, setEditAnswers] = useState<SimplifiedAnswer[]>([]);
  const [customAnswer, setCustomAnswer] = useState("");
  const [deletedAnswerCheck, setDeletedAnswerCheck] = useState(false);
  const [answersWithoutDeleted, setAnswersWithoutDeleted] = useState<SimplifiedAnswer[]>([])
  const location = useLocation();
  const [dateValue, setDateValue] = useState(moment(milestoneDate).format("MMMM Do, YYYY"));
  const [checked, setChecked] = useState(milestoneAsFirstLine);
  const [checkHasPressed, setCheckHasPressed] = useState(false)

  const isCustomOrFirstEvent : boolean = milestoneType?.age_weeks === - 800 || milestoneType?.age_weeks === - 1
  let missingContent: any =queryString.parse(location.search).both;
  //we should not show answers of type first event
  useEffect(() => {
    setEditAnswers(state =>
      !!state.length ? state : answers.filter(a => a.answer && a.type !== 'first_event' && a.type !== 'pregnancy').map(a => ({ ...a }))
    );
  }, [answers]);

  useEffect(() => {
    setChecked(milestoneAsFirstLine)
  }, [milestoneAsFirstLine])

  useEffect(() => {
    if(!deletedAnswerCheck) {
      setAnswersWithoutDeleted(editAnswers)
    }
  }, [editAnswers])

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (result.source.index !== result.destination?.index) {
        const newOrder = arrayMove(
          [...editAnswers],
          result.source.index,
          result.destination?.index ? result.destination?.index : 0
        );

        setEditAnswers(newOrder);
      }
    },
    [editAnswers, setEditAnswers]
  );

  useEffect(() => {
    const toEdit = cloneDeep(editAnswers);
    if(!!missingContent) {
      onOk(
        editTitle,
        editSubTitle,
        toEdit
      );
    }
  }, [missingContent])

  useEffect(() => {
    if(checked) {
      setEditSubTitle(dateValue)
    }
  }, [checked, dateValue])

  return (
    <Modal
      closable={false}
      visible={visible}
      onCancel={(e) => {
        setEditAnswers(answersWithoutDeleted);
        setDeletedAnswerCheck(false);
        if(checkHasPressed) {
          setChecked(!checked)
          setMilestoneAsFirstLine!(!checked);
        }
        setCheckHasPressed(false) 
        onCancel(e);
      }}
      onOk={() => {
        const answer = customAnswer

        const toEdit = !!customAnswer.length ? cloneDeep([...editAnswers, {
          answer,
          question_id: Math.floor((Math.random() * 100000) + 10000),
          type: "custom"
        }]) : cloneDeep(editAnswers);
        if(!!answer.length) {
          setEditAnswers(state => [
            ...state,
            {
              answer,
              question_id: Math.floor((Math.random() * 100000) + 10000),
              type: "custom"
            }
          ]);
        }
       
        onOk(
          editTitle,
          editSubTitle,
          toEdit
        );

        if(!!customAnswer) {
          setCustomAnswer("");
        } 
        setDeletedAnswerCheck(false);
      }}
    >
      { isCustomOrFirstEvent ? 
        (
          <>
            <div className="row">
              <div className="col-12">
                <label>Milestone Date</label>
                <DatePicker
                  value={moment(dateValue,"MMMM Do, YYYY" )}
                  format={"MMMM Do, YYYY"}
                  placeholder="Date"
                  className="w-100 my-1"
                  disabledDate={(current) => {
                    if(milestoneType?.age_weeks === -800) {
                      return moment() < current!
                    } else {
                      return false
                    }  
                  }}
                  onChange={(value) => {
                    if(value && setMilestoneDate) {
                      setDateValue(value?.format("MMMM Do, YYYY"))
                      setMilestoneDate(value?.format("YYYY-MM-DD 00:00:00"))
                    }
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 my-1 ">
                <Checkbox className="content-edit-checkbox" checked={checked} onChange={() => {
                 setCheckHasPressed(true)
                  setChecked(!checked)
                  if(!!setMilestoneAsFirstLine) {
                    setMilestoneAsFirstLine(!checked)
                  }
                }} >Use Milestone Date as First Line </Checkbox>
              </div>
            </div>
        </> 
        ) 
      : null 
      }
      { !checked ? 
        (
          <>
            <label>First Line</label>
            <Input
              className="w-100 my-1"
              value={editSubTitle}
              onChange={e => setEditSubTitle(e.target.value)}
              maxLength={30}
            />
            <p>{editSubTitle.length}/30</p>
          </>
        ) 
        : null
      }
      <label>Second Line</label>
      <Input
        className="w-100 my-1"
        value={editTitle}
        onChange={e => setEditTitle(e.target.value)}
        maxLength={30}
      />
      <p>{editTitle.length}/30</p>
      <label>Body Text</label>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="answer-list">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {editAnswers
              // .filter(answer => answer.answer)
              .map(
                (editAnswer: SimplifiedAnswer, index: number) => (
                  <Draggable draggableId={`answer-${index}`} index={index} key={index}>
                    {provided => (
                      <div
                        className="answer-orderable"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <AnswerOrderable
                          item={editAnswer}
                          index={index}
                          removeAnswer={removeAnswer}
                          setEditAnswers={setEditAnswers}
                          editAnswers={editAnswers}
                          setDeletedAnswerCheck={setDeletedAnswerCheck}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>      
      <Input
        className="w-100 my-1"
        addonAfter={
          <Icon
            type="plus-circle"
            className="clickable"
            onClick={() => {
              if (!!customAnswer) {
                let periodString = "";
                if (!isBirthAnnoMilestone)
                  periodString = ".";
                const answer =
                  customAnswer.charAt(customAnswer.length - 1) === "."
                    ? capitalizeText(customAnswer)
                    : `${capitalizeText(customAnswer)}${periodString}`;

                //addCustomAnswer(answer);
                setEditAnswers(state => [
                  ...state,
                  {
                    answer,
                    // question_id: state.reduce(
                    //   (small, current) =>
                    //     current.question_id !== null &&
                    //     current.question_id >= small
                    //       ? small - 1
                    //       : small,
                    //   -999
                    // ),
                    question_id: Math.floor((Math.random() * 100000) + 10000),
                    type: "custom"
                  }
                ]);
                setCustomAnswer("");
              }
            }}
          />
        }
        value={customAnswer}
        placeholder="Add additional body text"
        onChange={e => setCustomAnswer(e.target.value)}
      />
    </Modal>
  );
};

export default ContentEditAnswers;
