/**
 * Some big functions of Save Component to Reorganize
 */
import { Modal } from "antd";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import Cropper from "react-cropper";
import { BASE_URL } from "../../../env";
import {
  Layout,
  QuestionAnswer,
  SaveBabyPage,
  SaveBabyPagePhoto,
  SimplifiedAnswer,
} from "../../../interfaces";
import {
  getBase64,
  getFileFromUrl,
  getImage,
  isValidImage,
} from "../../../utils/image";
import { getAspectFromLayout } from "../../../utils/layouts";
import axios from "axios";
import { isGreaterAllowedThanMaxHeight } from "../../../utils/size";
import { isMobileSafari } from "react-device-detect";

export interface PhotoSelected {
  index: number | null;
  edit: boolean;
  base64: string;
  base64_cropped?: string;
  croppedData?: Cropper.Data;
  imageData?: Cropper.ImageData;
  aspect: number;
  side?: string;
}

export const initialPhotoState: PhotoSelected = {
  index: null,
  edit: false,
  base64: "",
  aspect: 1,
};

export const getPlaceholderB64 = async () => {
  const placeHolderFile = (
    await axios.get(
      `${""}${"https://babypage-staging.s3.amazonaws.com/public/missingImagePlaceholder/add_babypage_placeholder.png"}`,
      { responseType: "blob" }
    )
  ).data;
  const placeHolderB64 = await getBase64(placeHolderFile);

  return placeHolderB64;
};

/**
 * On Click on a Gallery Photo
 */
export const photoClick = async (
  index: number,
  photo: SaveBabyPagePhoto,
  inputRef: React.RefObject<HTMLInputElement>,
  layout: Layout,
  setPhotoSelected: React.Dispatch<React.SetStateAction<any>>,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  step?: number,
  side?: string
) => {
  const placeHolderFile = (
    await axios.get(
      `${""}${"https://babypage-staging.s3.amazonaws.com/public/missingImagePlaceholder/add_babypage_placeholder.png"}`,
      { responseType: "blob" }
    )
  ).data;
  const placeHolderB64 = await getBase64(placeHolderFile);

  if (placeHolderB64 === photo.base64) {
    setModalOpen(true);
  }

  if (
    // Check if is adding photo
    photo.base64_cropped === ""
  ) {
    if (!!inputRef && !!inputRef.current) {
      setPhotoSelected((state: PhotoSelected) => ({ ...state, index, side }));
      if (step === 0) {
        photo.side = "left";
      } else if (step === 1) {
        photo.side = "right";
      }
      setModalOpen(true);
      // inputRef.current.click(); // Toggle input click to select photo
    }
  } else if (photo.base64 !== undefined) {
    // If is editing photo
    setPhotoSelected((state: PhotoSelected) => ({
      ...state,
      aspect: getAspectFromLayout(layout, index),
      croppedData: !!photo.croppedData ? { ...photo.croppedData } : undefined,
      imageData: !!photo.imageData ? { ...photo.imageData } : undefined,
      index,
      edit: placeHolderB64 !== photo.base64,
      base64: photo.base64!,
      side: photo.side,
    }));
  }
};

export const profilePhotoClick = async (
  photo: SaveBabyPagePhoto,
  inputRef: React.RefObject<HTMLInputElement>,
  setPhotoSelected: React.Dispatch<React.SetStateAction<any>>
) => {
  const placeHolderFile = (
    await axios.get(
      `${""}${"https://babypage-staging.s3.amazonaws.com/public/missingImagePlaceholder/add_babypage_placeholder.png"}`,
      { responseType: "blob" }
    )
  ).data;
  const placeHolderB64 = await getBase64(placeHolderFile);

  if (
    // Check if is adding photo
    photo.base64_cropped === ""
  ) {
    if (!!inputRef && !!inputRef.current) {
      setPhotoSelected((state: PhotoSelected) => ({ ...state }));
    }
  } else if (photo.base64 !== undefined) {
    // If is editing photo
    setPhotoSelected((state: PhotoSelected) => ({
      ...state,
      aspect: 1,
      croppedData: !!photo.croppedData ? { ...photo.croppedData } : undefined,
      imageData: !!photo.imageData ? { ...photo.imageData } : undefined,
      edit: placeHolderB64 !== photo.base64,
      base64: photo.base64!,
      side: photo.side,
    }));
  }
};

/**
 * On select a new Photo file (Input Change)
 */
export const selectImage = async (
  e: React.ChangeEvent<HTMLInputElement>,
  photoSelected: PhotoSelected,
  photos: SaveBabyPagePhoto[],
  setPhotos: any,
  layout: Layout,
  setPhotoSelected: React.Dispatch<any>,
  inputRef: React.RefObject<HTMLInputElement>,
  setLoading: React.Dispatch<any>
) => {
  const index = photoSelected.index;

  const side = photoSelected.side;
  if (e.target.files && e.target.files.length && index !== null && index >= 0) {
    const newPhotos = [...photos];
    const file = e.target.files[0];
    let fileResized: File | Blob | null = null;
    const hasRestrictionOfHeight = await isGreaterAllowedThanMaxHeight(file);
    if (hasRestrictionOfHeight && isMobileSafari) {
      Modal.error({
        title: "Error",
        content: "Please choose an image less than 3300 pixels tall.",
      });
      return;
    }

    // Check image type
    if (!isValidImage(file.type)) {
      Modal.error({
        title: "Error",
        content: "Please select a valid image",
      });
      return setPhotoSelected(initialPhotoState);
    } else if (file.type.includes("webp")) {
      Modal.error({
        title: "Error",
        content: "Please select a valid image",
      });
      return setPhotoSelected(initialPhotoState);
    }

    setLoading(true);

    // Generate new Filename
    const fileName =
      Date.now().toString() + file.name.substring(file.name.lastIndexOf("."));

    newPhotos[index].missing = false;
    // Create new File
    newPhotos[index].file = !!fileResized
      ? fileResized
      : new File([file], fileName, {
          type: file.type,
        });

    // Generate base64
    const base64 = await getBase64(!!fileResized ? fileResized : file);

    setLoading(false);
    if (base64 !== null) {
      newPhotos[index].base64 = base64.toString();
      newPhotos[index].isLocal = true;

      // SetPhotos and Unselect Photo
      setPhotos(newPhotos, layout);

      // Reset input value
      if (!!inputRef && !!inputRef.current) {
        inputRef.current.value = "";
      }

      setPhotoSelected((_: PhotoSelected) => ({
        ...initialPhotoState,
        aspect: getAspectFromLayout(layout, index),
        index,
        edit: true,
        base64: base64.toString(),
        side,
      }));
      return;
    }
  }
  setPhotoSelected(initialPhotoState);
};

/**
 * On select a gallery photo (Input Change)
 */
export const selectImageURL = async (
  imgUrl: string,
  photoSelected: PhotoSelected,
  photos: SaveBabyPagePhoto[],
  setPhotos: any,
  layout: Layout,
  setPhotoSelected: React.Dispatch<any>,
  inputRef: React.RefObject<HTMLInputElement>,
  setLoading: React.Dispatch<any>,
  side?: string
) => {
  const index = photoSelected.index!;

  const newPhotos = [...photos];
  const file = await getFileFromUrl(
    imgUrl,
    imgUrl.indexOf("s3.amazonaws.com") !== -1
      ? `${BASE_URL}api/avoid-cors?url=`
      : undefined
  );

  let fileResized: File | Blob | null = null;

  if (file === null) {
    return setPhotoSelected({
      ...initialPhotoState,
    });
  }

  const hasRestrictionOfHeight = await isGreaterAllowedThanMaxHeight(file);
  if (hasRestrictionOfHeight && isMobileSafari) {
    Modal.error({
      title: "Error",
      content: "Please choose an image less than 3300 pixels tall.",
    });
    return;
  }

  // Check image type
  if (!isValidImage(file.type)) {
    Modal.error({
      title: "Error",
      content: "Please select a valid image",
    });
    return setPhotoSelected(initialPhotoState);
  }

  setLoading(true);

  // Generate new Filename
  const fileName = Date.now().toString() + ".jpg";

  newPhotos[index].missing = false;
  // Create new File
  newPhotos[index].file = !!fileResized
    ? fileResized
    : new File([file], fileName, {
        type: file.type,
      });

  // Generate base64
  const base64 = await getBase64(!!fileResized ? fileResized : file);

  setLoading(false);

  if (base64 !== null) {
    newPhotos[index].base64 = base64.toString();

    if (imgUrl.includes("photos")) {
      newPhotos[index].isLocal = false;
      newPhotos[index].name = imgUrl;
    } else {
      newPhotos[index].isLocal = true;
    }

    // SetPhotos and Unselect Photo
    setPhotos(newPhotos, layout);

    // Reset input value
    if (!!inputRef && !!inputRef.current) {
      inputRef.current.value = "";
    }

    setPhotoSelected((_: PhotoSelected) => ({
      ...initialPhotoState,
      aspect: getAspectFromLayout(layout, index),
      index,
      edit: true,
      base64: base64.toString(),
      side,
    }));
    return;
  }

  setPhotoSelected(initialPhotoState);
};

export const selectOneImage = async (
  e: React.ChangeEvent<HTMLInputElement>,
  photoSelected: PhotoSelected,
  photo: SaveBabyPagePhoto,
  setPhoto: any,
  setPhotoSelected: React.Dispatch<any>,
  inputRef: React.RefObject<HTMLInputElement>,
  setLoading: React.Dispatch<any>
) => {
  const index = photoSelected.index;
  if (e.target.files && e.target.files.length) {
    const newPhoto = photo;
    const file = e.target.files[0];
    let fileResized: File | Blob | null = null;
    // Check image type
    if (!isValidImage(file.type)) {
      Modal.error({
        title: "Error",
        content: "Please select a valid image",
      });
      return setPhotoSelected(initialPhotoState);
    } else if (file.type.includes("webp")) {
      Modal.error({
        title: "Error",
        content: "Please select a valid image",
      });
      return setPhotoSelected(initialPhotoState);
    }

    setLoading(true);

    // Generate new Filename
    const fileName =
      Date.now().toString() + file.name.substring(file.name.lastIndexOf("."));

    newPhoto.missing = false;
    // Create new File
    newPhoto.file = !!fileResized
      ? fileResized
      : new File([file], fileName, {
          type: file.type,
        });

    // Generate base64
    const base64 = await getBase64(!!fileResized ? fileResized : file);

    setLoading(false);
    if (base64 !== null) {
      newPhoto.base64 = base64.toString();

      // SetPhotos and Unselect Photo
      setPhoto(newPhoto);

      // Reset input value
      if (!!inputRef && !!inputRef.current) {
        inputRef.current.value = "";
      }

      setPhotoSelected((_: PhotoSelected) => ({
        ...initialPhotoState,
        index,
        edit: true,
        base64: base64.toString(),
      }));
      return;
    }
  }
  setPhotoSelected(initialPhotoState);
};

/**
 * On select a new Photo (Input Change)
 */
export const selectOneImageURL = async (
  imgUrl: string,
  photoSelected: PhotoSelected,
  photo: SaveBabyPagePhoto,
  setPhoto: any,
  setPhotoSelected: React.Dispatch<any>,
  inputRef: React.RefObject<HTMLInputElement>,
  setLoading: React.Dispatch<any>
) => {
  const index = photoSelected.index!;

  const newPhoto = photo;
  const file = await getFileFromUrl(
    imgUrl,
    imgUrl.indexOf("s3.amazonaws.com") !== -1
      ? `${BASE_URL}api/avoid-cors?url=`
      : undefined
  );

  let fileResized: File | Blob | null = null;

  if (file === null) {
    return setPhotoSelected({
      ...initialPhotoState,
    });
  }
  // Check image type
  if (!isValidImage(file.type)) {
    Modal.error({
      title: "Error",
      content: "Please select a valid image",
    });
    return setPhotoSelected(initialPhotoState);
  }

  setLoading(true);

  // Generate new Filename
  const fileName = Date.now().toString() + ".jpg";

  newPhoto.missing = false;
  // Create new File
  newPhoto.file = !!fileResized
    ? fileResized
    : new File([file], fileName, {
        type: file.type,
      });

  // Generate base64
  const base64 = await getBase64(!!fileResized ? fileResized : file);

  setLoading(false);

  if (base64 !== null) {
    newPhoto.base64 = base64.toString();
    // SetPhotos and Unselect Photo
    setPhoto(newPhoto);

    // Reset input value
    if (!!inputRef && !!inputRef.current) {
      inputRef.current.value = "";
    }

    setPhotoSelected((_: PhotoSelected) => ({
      ...initialPhotoState,
      index,
      edit: true,
      base64: base64.toString(),
    }));
    return;
  }

  setPhotoSelected(initialPhotoState);
};

export const finishCrop = async (
  cropperRef: Cropper,
  photoSelected: PhotoSelected,
  photos: SaveBabyPagePhoto[],
  setPhotos: any,
  layout: Layout,
  setLoading: React.Dispatch<any>,
  setPhotoSelected: React.Dispatch<any>
) => {
  const index = photoSelected.index;

  if (index !== null && !!photos[index]) {
    setLoading(true);
    const newPhotos = [...photos];
    try {
      newPhotos[index].base64_cropped = cropperRef
        .getCroppedCanvas()
        .toDataURL("image/jpeg");
      newPhotos[index].croppedData = !!photoSelected.croppedData
        ? { ...photoSelected.croppedData }
        : undefined;
      newPhotos[index].imageData = !!photoSelected.imageData
        ? { ...photoSelected.imageData }
        : undefined;
      newPhotos[index].oldAspect = photoSelected.aspect;
    } catch (error) {
      Modal.error({
        title: "Error",
        content: "An error occurred while cropping the image. Try again later",
      });
      return;
    }

    setPhotos(newPhotos, layout);
    setPhotoSelected(initialPhotoState);
    setLoading(false);
  }
};

export const finishOneCrop = async (
  cropperRef: Cropper,
  photoSelected: PhotoSelected,
  photo: SaveBabyPagePhoto,
  setPhoto: any,
  setLoading: React.Dispatch<any>,
  setPhotoSelected: React.Dispatch<any>
) => {
  if (!!photo) {
    setLoading(true);
    const newPhoto = photo;
    try {
      newPhoto.base64_cropped = cropperRef
        .getCroppedCanvas()
        .toDataURL("image/jpeg");
      newPhoto.croppedData = !!photoSelected.croppedData
        ? { ...photoSelected.croppedData }
        : undefined;
      newPhoto.imageData = !!photoSelected.imageData
        ? { ...photoSelected.imageData }
        : undefined;
      newPhoto.oldAspect = photoSelected.aspect;
    } catch (error) {
      Modal.error({
        title: "Error",
        content: "An error occurred while cropping the image. Try again later",
      });
      return;
    }

    setPhoto(newPhoto);
    setPhotoSelected(initialPhotoState);
    setLoading(false);
  }
};

export const addCustomAnswerHelper = (
  setBabyPage: React.Dispatch<React.SetStateAction<SaveBabyPage>>,
  answer: string
) => {
  setBabyPage((state) => ({
    ...state,
    answers: [
      ...cloneDeep(state.answers),
      {
        answer,
        answer_string: [answer],
        position: !!state.answers.length
          ? state.answers[state.answers.length - 1].position + 1
          : 1,
        question_id: state.answers.reduce(
          (small, current) =>
            current.question_id !== null && current.question_id >= small
              ? small - 1
              : small,
          -999
        ),
        type: "custom",
      },
    ],
    label_url: undefined,
    thumbnail_content: undefined,
  }));
};

export const setCustomAnswersHelper = (
  setBabyPage: React.Dispatch<React.SetStateAction<SaveBabyPage>>,
  changedAnswers: SimplifiedAnswer[]
) => {
  setBabyPage((state) => ({
    ...state,
    answers: ((): QuestionAnswer[] => {
      if (!changedAnswers || !changedAnswers.length) return state.answers;
      const newAnswers = state.answers.filter((a) => {
        const changedAnswerIndex = changedAnswers.findIndex(
          (c) => c.question_id !== null && c.question_id === a.question_id
        );

        return !(
          changedAnswerIndex >= 0 &&
          (!changedAnswers[changedAnswerIndex].answer ||
            !changedAnswers[changedAnswerIndex].answer.length)
        );
      });

      for (let i = 0; i < newAnswers.length; i++) {
        const changedAnswerIndex = changedAnswers.findIndex(
          (c) =>
            c.question_id !== null &&
            c.question_id === newAnswers[i].question_id
        );

        if (changedAnswerIndex >= 0) {
          const changedAnswer = {
            ...cloneDeep(newAnswers[i]),
            ...cloneDeep(changedAnswers[changedAnswerIndex]),
            question_id: newAnswers.reduce(
              (small, current) =>
                current.question_id !== null && current.question_id >= small
                  ? small - 1
                  : small,
              -999
            ),
            type: "custom",
          };

          changedAnswers.splice(changedAnswerIndex, 1);

          newAnswers[i] = changedAnswer;
        }
      }

      const changedHeight = changedAnswers.find(
        (c) => c.type === "height_weight"
      );

      if (!!changedHeight) {
        newAnswers.unshift({
          answer: changedHeight.answer,
          answer_string: [changedHeight.answer],
          position: !!state.answers.length
            ? state.answers[state.answers.length - 1].position + 1
            : 1,
          question_id: state.answers.reduce(
            (small, current) =>
              current.question_id !== null && current.question_id >= small
                ? small - 1
                : small,
            -999
          ),
          type: "custom",
        });
      }

      return !!changedHeight
        ? newAnswers.filter((a) => a.question_id !== null)
        : newAnswers;
    })(),
    label_url: undefined,
    thumbnail_content: undefined,
  }));
};

export const setSortedAnswersHelper = (
  setBabyPage: React.Dispatch<React.SetStateAction<SaveBabyPage>>,
  orderedAnswers: SimplifiedAnswer[]
) => {
  setBabyPage((state) => ({
    ...state,
    answers: ((): QuestionAnswer[] => {
      const newAnswers = orderedAnswers.map((a, i) => {
        const answer = state.answers.filter(
          (c) => c.answer !== null && c.answer === a.answer
        );

        if (answer[0]) {
          answer[0].answer = a.answer;
        }

        return answer[0]
          ? answer[0]
          : {
              question_id: a.question_id,
              position: i,
              answer_string: !!a.answer_string ? a.answer_string : [a.answer],
              answer: a.answer,
              type: a.type,
            };
      });

      return newAnswers;
    })(),
    label_url: undefined,
    thumbnail_content: undefined,
  }));
};

// /**
//  * Get the new sizes of a rectangle after rotated.
//  * @param {Object} data - The original sizes.
//  * @returns {Object} The result sizes.
//  */
// export function getRotatedSizes({ width, height, degree }) {
//   degree = Math.abs(degree) % 180;

//   if (degree === 90) {
//     return {
//       width: height,
//       height: width,
//     };
//   }

//   const arc = ((degree % 90) * Math.PI) / 180;
//   const sinArc = Math.sin(arc);
//   const cosArc = Math.cos(arc);
//   const newWidth = (width * cosArc) + (height * sinArc);
//   const newHeight = (width * sinArc) + (height * cosArc);

//   return degree > 90 ? {
//     width: newHeight,
//     height: newWidth,
//   } : {
//     width: newWidth,
//     height: newHeight,
//   };
// }

export const calculateCropped = (
  zoom: number,
  imageData: Cropper.ImageData,
  origin: number[],
  rotate: number,
  aspect: number,
  source: "web" | "ios" | "android" = "web"
): Cropper.Data => {
  const rotated = rotate % 180 === 90;

  const height = rotated ? imageData.naturalWidth : imageData.naturalHeight;
  const width = rotated ? imageData.naturalHeight : imageData.naturalWidth;

  const sizePixels =
    width >= height * aspect
      ? {
          width:
            (height * (zoom > 1 && aspect === 1.5 ? width / height : aspect)) /
            zoom,
          height: height / zoom,
        }
      : {
          width:
            zoom > 1 && aspect !== 2 && aspect !== 1.5
              ? height / zoom
              : width / zoom,
          height: width / aspect / zoom,
        };

  return {
    ...sizePixels,
    x: origin.length === 2 ? origin[0] : (width - sizePixels.width) / 2,
    y: origin.length === 2 ? origin[1] : (height - sizePixels.height) / 2,
    rotate,
    scaleX: 1,
    scaleY: 1,
  };
};

export const calculateZoom = (
  photo: SaveBabyPagePhoto,
  aspect: number
): number => {
  if (
    !!photo.imageData &&
    !!photo.croppedData &&
    photo.imageData.naturalWidth !== photo.croppedData.width &&
    photo.imageData.naturalHeight !== photo.croppedData.height
  ) {
    const rotated = photo.croppedData.rotate % 180 === 90;

    const height = rotated
      ? photo.imageData.naturalWidth
      : photo.imageData.naturalHeight;
    const width = rotated
      ? photo.imageData.naturalHeight
      : photo.imageData.naturalWidth;

    const zoom =
      width >= height * aspect
        ? height / photo.croppedData.height
        : width / photo.croppedData.width;

    return zoom;
  }

  return 1;
};

export const calculateOrigin = (photo: SaveBabyPagePhoto): number[] =>
  !!photo.croppedData ? [photo.croppedData.x, photo.croppedData.y] : [];

export const calculateOriginPercent = (photo: SaveBabyPagePhoto): number[] => {
  if (!!photo.imageData && !!photo.croppedData) {
    const rotated = photo.croppedData.rotate % 180 === 90;

    const height = rotated
      ? photo.imageData.naturalWidth
      : photo.imageData.naturalHeight;
    const width = rotated
      ? photo.imageData.naturalHeight
      : photo.imageData.naturalWidth;

    return [photo.croppedData.x / width, photo.croppedData.y / height];
  }

  return [];
};
