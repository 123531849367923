import info from "../package.json";

export const WEB_VERSION = info.version;
export const PRODUCTION = false;
export const BASE_URL = "https://babypagedev.teravisiontech.cloud/";
export const PAGE_URL = "https://dev-app.babypage.com/";
export const PAYPAL_URL =
  "https://www.sandbox.paypal.com/myaccount/autopay/connect"; // Used for paypal manage
export const clientId = "984f69b8-09f1-4e48-aa22-4d4857a50e46"; // Client ID DEV
export const clientSecret = "8pqP1QIVlS0stgtJrrSUABeDGpzjJybwNyhEyN33"; // Client Secret DEV
export const apiVersion = "application/x.babypage.v1.0.0+json"; // API Version
export const storageKey = "@babypageStorage"; // This is the key on localStorage
export const highRes = { width: 3000, height: 3000 }; // HighResolution to Save
export const highResContent = { width: 2000, height: 2000 }; // HighResolution to Save
export const STRIPE_KEY = "pk_test_S1DXzMXptxyov3k4pMq3Z6W0"; // Paypal API Key STAGING STRIPE 
export const FACEBOOK_PIXEL = {
  name: "BabyPage Test Pixel",
  id: "126914508124403"
};
export const AMPLITUDE = {
  apiKey: "bc163e05f3c9075fb505c2976df208ed"
};
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDwYyZ9ygaVwPyVrgFvM9YhqalWx_U3i6I",
  authDomain: "babypage-dev.firebaseapp.com",
  databaseURL: "https://babypage-dev.firebaseio.com",
  projectId: "babypage-dev",
  storageBucket: "babypage-dev.appspot.com",
  messagingSenderId: "305786423806",
  appId: "1:305786423806:web:bcd5bfae1cc4e116cc98af",
  measurementId: "G-TNRHEP5HTF"
};
export const PAYPAL = {
  apiKey:
    "AVMxMLV_0Kng1fc-WECeQHWkUF-mhcZJlkk7OvaZ7-hii6GFhv6BA52mMCFPo1TbbT7YX4FNS8h3DMAu",
  merchantId: "EVUSSQMP3UUQ8",
  mode: "sandbox",
  commit: true,
  locale: "en_US",
  style: {
    label: "checkout", // checkout | credit | pay | buynow | generic
    size: "responsive", // small | medium | large | responsive
    shape: "rect", // pill | rect
    color: "blue", // gold | blue | silver | black
    tagline: false
  },
  buttons: {
    monthly: "UGSVC4K6ASHYY",
    three_month: "NV69VGU6YK8KU",
    one_year: "3RMJ2C8VJRLPL"
  }
};
