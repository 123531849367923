import { Icon, Spin } from "antd";
import React, { useState } from "react";

interface Props {
  thumbnail: string;
  title: string;
  isSelected?: boolean;
}

const ImageProgress: React.FC<Props> = ({
  thumbnail,
  title,
  isSelected = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };

  return (
    <>
      {loading && (
        <div className="w-100">
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        </div>
      )}
      {error && <Icon type="exclamation-circle" />}
      {!error && (
        <>
          <img
            src={thumbnail}
            alt={title}
            className={`w-100 ${isSelected ? "selected-cover" : ""}`}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
          {!!isSelected && (
            <Icon type="check-circle" theme="filled" style={{ fontSize: 60 }} />
          )}
        </>
      )}
    </>
  );
};

export default ImageProgress;
