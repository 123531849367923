import { Icon } from 'antd';
import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';
import { highRes } from '../../env';
import { SaveBabyPagePhoto } from '../../interfaces';
import { dynamicSize, getProportionalSize } from '../../utils/size';
import { Background } from '../../interfaces/background';
import './Layout1A.scss'

interface Props {
  photos: SaveBabyPagePhoto[];
  onClickPhoto?: (index: number, photo: SaveBabyPagePhoto) => void;
  onSortEnd: SortEndHandler;
  ignoreMargin?: boolean;
  loading: boolean;
  backgroundSelected?: Background;
}

interface LayoutProps {
  photo: SaveBabyPagePhoto;
  photoIndex: number;
  onClickPhoto?: (index: number, photo: SaveBabyPagePhoto) => void;
  col: number;
  padding?: string;
  height?: string;
}

const SortableItem = SortableElement(
  ({
    photo,
    photoIndex,
    onClickPhoto,
    col,
    padding = '0px',
    height = '100%',
  }: LayoutProps) => {

    const checkMissing = () => {
      if(photo.base64_cropped !== '' && photo.missing) {
        return false
      } else if(photo.base64_cropped !== '' && !photo.missing){
        return true
      }
    }
    return checkMissing() ? (
      <div
        key={photoIndex}
        className={`col-${col}`}
        onClick={() => (!!onClickPhoto ? onClickPhoto(photoIndex, photo) : {})}
        style={{ padding, height }}
      >
        <img
          src={photo.base64_cropped}
          alt='Baby 1'
          className='baby-photo w-100 h-100'
        />
      </div>
    ) : (
      <div
        key={photoIndex}
        className={`col-${col}`}
        onClick={() => (!!onClickPhoto ? onClickPhoto(photoIndex, photo) : {})}
        style={{ padding, height }}
      >
        <div className='add-photo w-100 h-100'>
          <Icon type='plus-circle' theme='filled' style={{ fontSize: 36 }} />
        </div>
      </div>
    );
  }
);

// @ts-ignore
const SortableLayout = SortableContainer(props => props.children);

const Layout3A: React.FC<Props> = ({
  photos,
  onClickPhoto,
  ignoreMargin,
  onSortEnd,
  loading,
  backgroundSelected,
}) => {
  return (
    <div
      className='w-100 h-100'
      style={{
        backgroundColor: !!backgroundSelected
          ? backgroundSelected.color
          : '#ffffff',
      }}
    >
    <div style={{width: "100%", height:"100%"}}>
        {!loading ? (
            <div className="click-through" style={{width: "93%", height:"93%", border:"2px dashed #e70c0c", zIndex: 999,  position: "absolute",  transform: "translate(-50%, -50%)", top:"50%", left:"50%"}} />
          ): null }
        <SortableLayout pressDelay={200} onSortEnd={onSortEnd} axis='xy'>
          <div className='row justify-content-center align-items-center mx-auto h-100'>
            <div className='col-6 p-0 w-100 h-100'>
              <div className='row justify-content-center align-items-center h-100 w-100 mx-auto'>
                <SortableItem
                  index={0}
                  photoIndex={0}
                  photo={photos[0]}
                  onClickPhoto={onClickPhoto}
                  col={12}
                  height='50%'
                  padding={
                    ignoreMargin
                      ? '0px'
                      : `0px ${
                          loading
                            ? 5
                            : 1.5
                        }px ${
                          loading
                            ? 5
                            : 1.5
                        }px 0px`
                  }
                />
                <SortableItem
                  index={2}
                  photoIndex={2}
                  photo={photos[2]}
                  onClickPhoto={onClickPhoto}
                  col={12}
                  height='50%'
                  padding={
                    ignoreMargin
                      ? '0px'
                      : `${
                          loading
                            ? 5
                            : 1.5
                        }px ${
                          loading
                            ? 5
                            : 1.5
                        }px 0px 0px`
                  }
                />
              </div>
            </div>
            <SortableItem
              index={1}
              photoIndex={1}
              photo={photos[1]}
              onClickPhoto={onClickPhoto}
              col={6}
              padding={
                ignoreMargin
                  ? '0px'
                  : `0px 0px 0px ${
                      loading
                        ? 5
                        : 1.5
                    }px`
              }
            />
          </div>
        </SortableLayout>
      </div>
    </div>
  );
};

export default Layout3A;
