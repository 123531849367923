import { Button, Icon, Modal, notification } from "antd";
import html2canvas from "html2canvas";
import { isEqual } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import queryString from "query-string";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import BaseLayout from "../../../components/BaseLayout/BaseLayout";
import Babypages from "../../../components/Dashboard/Babypages";
import ModalPlus from "../../../components/ModalPlus/ModalPlus";
import RouteLeavingGuard from "../../../components/RouteLeavingGuard/RouteLeavingGuard";
import EditBorderIllustration from "../../../components/SaveBabyPage/EditBorderIllustration";
import EditTextBackground from "../../../components/SaveBabyPage/EditTextBackground";
import Finish from "../../../components/SaveBabyPage/Finish";
import Questions from "../../../components/SaveBabyPage/Questions";
import SelectPhotos from "../../../components/SaveBabyPage/SelectPhotos";
import backgrounds from "../../../data/backgrounds";
import { BASE_URL, highRes, highResContent } from "../../../env";
import {
  Baby,
  Background,
  Border,
  BpPhotoDB,
  Illustration,
  initialBabyPage,
  Layout,
  Milestone,
  MilestonesBaby,
  QuestionAnswer,
  QuestionItem,
  SaveBabyPage,
  SaveBabyPagePhoto,
  SimplifiedAnswer,
  User,
  DraftBabyPage,
  Entry
} from "../../../interfaces";
import { AppState, sagaMiddleware } from "../../../store";
import { BabyState } from "../../../store/ducks/baby";
import {
  fetchBabyPageById,
  saveBabyPageSaga,
} from "../../../store/sagas/babyPage";
import { checkUserModal } from "../../../store/sagas/form";
import {
  fetchPhotos,
  FileUploadedS3,
  FileUploadS3,
  savePhotosS3,
} from "../../../store/sagas/image";
import { fetchQuestions } from "../../../store/sagas/questions";
import { saveEntryDashboard } from "../../../store/sagas/dashboard";
import {
  cleanQuestions,
  exportAnswer,
  exportGrowthAnswer,
  multipleAnswers
} from "../../../utils/bpQuestions";
import {
  b64toBlob,
  canvasToBlobPromise,
  checkPhotoLayouts,
  getBase64FromFileUrl,
  getPhotoFile,
} from "../../../utils/image";
import { getAspectFromLayout, getLayoutInfos } from "../../../utils/layouts";
import { trackAction } from "../../../utils/marketing";
import {
  addSuffix,
  capitalizeText,
  capitalizeTexts,
  lastSegmentUrl,
} from "../../../utils/string";
import { delay } from "../../../utils/time";
import "./Save.scss";
import {
  addCustomAnswerHelper,
  calculateOrigin,
  calculateOriginPercent,
  calculateZoom,
  setCustomAnswersHelper,
  setSortedAnswersHelper,
  getPlaceholderB64
} from "./SaveHelper";
import { Fonts } from "../../../hooks/Fonts";
import { setDraftBabyPage } from "../../../store/sagas/bpHelper";
import {PRODUCTION} from "../../../env"
import { fetchBabies } from "../../../store/sagas/baby";
const STANDARD_WEEKS_FOR_PREGNANCY_MILESTONE = 40;
type Props = {
  user: User;
  babySelected?: Baby;
  milestones: MilestonesBaby[];
  borders: Border[];
  illustrations: Illustration[];
  baby: BabyState;
  draftBabyPage: DraftBabyPage;
};

const SaveBabyPagesPage: React.FC<Props> = ({
  milestones,
  user,
  babySelected,
  borders,
  illustrations,
  baby,
  draftBabyPage,
}) => {
  // Internal state management
  const history = useHistory();
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);

  const [freeUserModal, setFreeUserModal] = useState<boolean>(!!user.user_level ? false : true)
  const [milestone, setMilestone] = useState<Milestone | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [lastTextAnswers, setLastTextAnswers] = useState('');
  const [saveStep, setSaveStep] = useState<
    null | "genImg" | "sendImg" | "saveBabyPage"
  >(null);
  const [control, setControl] = useState({
    valid: false,
    step: 0,
  });
  const [babyPage, setBabyPage] = useState<SaveBabyPage>(
    cloneDeep(initialBabyPage)
  );

  const [questions, setQuestions] = useState<QuestionItem[]>([]);
  const [isTruncated, setIsTruncated] = useState(false);
  const [plusOpen, setPlusOpen] = useState(false);
  const [babyPageLimitPlusOpen, setBabyPageLimitPlusOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [ milestoneDate, setMilestoneDate] = useState<string>("")
  const [selectedPlus, setSelectedPlus] = useState({
    border: false,
    illustration: false,
    layout: false,
    font: false,
  });
  const isSelectedPlus =
    selectedPlus.border || selectedPlus.illustration || selectedPlus.layout || selectedPlus.font;
  const [saveWithoutPlus, setSaveWithoutPlus] = useState(false);
  const { confirm } = Modal;
  const isPhotoOnly = milestone?.age_weeks === -2;
  const isCustomOrFirstEvent  = milestone?.age_weeks === - 800 || milestone?.age_weeks === - 1
  const fonts = Fonts (!!user.user_level)
  const [milestoneAsFirstLine, setMilestoneAsFirstLine] = useState(false);
  const [isFutureDate, setIsFutureDate] = useState(false);
  const [entry, setEntry] = useState<Entry>
  ({ 
      entry_date: moment(),
      baby_id: babySelected!.id,
      height: 0,
      weight_oz: 0,
      weight_lbs: 0
  })

  const saveGrowthChartEntry = useCallback(() => {
    if (entry !== null && moment.isMoment(entry.entry_date)) {
      const entryToSave = {
        ...entry,
        entry_date: entry.entry_date.format("YYYY[-]MM[-]DD"),
        isEdit: undefined
      };

      setLoading(true);
      sagaMiddleware.run<any>(
        saveEntryDashboard,
        entryToSave,
        (error: any | null) => {
          if (error !== null) {
            Modal.error({
              title: "Error",
              content: error
            });
          } else {
            trackAction(
              "CreateGrowthChart",
              {
                google: {
                  event: "create_growth_chart",
                }
              },
              true
            );
          }
        }
      );
    }
  }, [entry, loading]);


 

  const checkIfEntryExist = useCallback((babyBirth) => {
    let growthEntry = {...entry}
    if(!!babyPage.id ) {
      const repairedChild = baby.babies.filter(baby => babyPage.child_id === baby.id )
      // REVISAR REPAIR
    }
    if(moment() < babyBirth) {
      setIsFutureDate(true);
    }

    const searchEntryHeight = babySelected!.heights.filter(
      height =>
        moment(height.entry_date).format("DDMMYYYY") ===
        babyBirth.format("DDMMYYYY")
    );

    const searchEntryWeight = babySelected!.weights.filter(
      weight =>
        moment(weight.entry_date).format("DDMMYYYY") ===
        babyBirth.format("DDMMYYYY")
    );

    if (searchEntryHeight.length > 0) {
      growthEntry.height = parseFloat(searchEntryHeight[0].height);
    }

    if (searchEntryWeight.length > 0) {
      const oz = parseInt(searchEntryWeight[0].oz);
      growthEntry.weight_lbs = ~~(oz / 16);
      growthEntry.weight_oz = oz % 16;
    }

    if (searchEntryHeight.length === 0 && searchEntryWeight.length === 0) {
    
      growthEntry.isEdit = false;
    } else {
   
      growthEntry.isEdit = true;
    }
    growthEntry.entry_date = babyBirth;
    return growthEntry
  }, [])
  
  let missingContent: any =queryString.parse(location.search).both;

  useEffect(()=>{
    document.body.classList.toggle("no-overflow");
  },[])
  
  useEffect(() => {
    let id: any = queryString.parse(location.search).id;

    if (user.user_level === 1) {
      return;
    }

    if (!id && baby && baby.babies.length) {
      const babyPagesCounter = baby.babies.reduce(
        (acc: number, baby: any) => acc + baby.babyPages.length,
        0
      );

      if (babyPagesCounter >= 15) {
        setBabyPageLimitPlusOpen(true);
      }
    }

    if (milestone?.age_weeks === -2) {
      setPlusOpen(true);
    }
  }, [user.user_level, milestone, history]);

  useEffect(() => {
    if (saveWithoutPlus) {
      saveBabyPage();
    }
  }, [saveWithoutPlus, babyPage.photos]);

  useEffect(() => {
    sagaMiddleware.run<any>(fetchPhotos);

    return () => {
      setBabyPage(cloneDeep(initialBabyPage));
      setControl({ valid: false, step: 0 });
    };
  }, []);

  // On start
  useEffect(() => {
    if (loaded) return;

    let id: any = queryString.parse(location.search).id; // If is update
    let from: any = queryString.parse(location.search).from;
    if (draftBabyPage && draftBabyPage.photos && !!from) {
      setBabyPage(draftBabyPage);
      if(user.user_level) {
        setControl({ valid: true, step: 4 });
        setSaveWithoutPlus(true);
      } else {
        setSelectedPlus({border: true, layout:true, illustration:true, font:true})
        setControl({ valid: true, step: 4 });
      }
      

      sagaMiddleware.run<any>(setDraftBabyPage, {});
    }

    if (!!id) {
      if (
        !!babySelected &&
        !!milestones &&
        !!milestones.length &&
        !!borders &&
        !!borders.length &&
        !!illustrations &&
        !!illustrations.length &&
        isEqual(babyPage, initialBabyPage)
      ) {
        setLoading(true);
        id = parseInt(id.toString());

        sagaMiddleware.run<any>(
          fetchBabyPageById,
          id,
          async (error: string | null, babyPageBD?: any) => {
            if (error !== null || !babyPageBD || !babyPageBD.new_version) {
              Modal.error({
                title: "Error",
                content: !!error ? error : "You can only edit new BabyPages",
              });
              history.push("/");
              setLoading(false);
              return;
            }

            setTitle(babyPageBD.title);
            setSubTitle(babyPageBD.subtitle);
            setMilestoneDate(babyPageBD.original_title)

            try {
              const borderSelected = !!babyPageBD.border
                ? borders.find(
                    (border) => border.image_url === babyPageBD.border
                  )
                : undefined;

              const illustrationSelected = !!babyPageBD.illustration
                ? illustrations.find(
                    (illustration) =>
                      illustration.image_url === babyPageBD.illustration
                  )
                : undefined;

              const backgroundSelected = !!babyPageBD.background_color
                ? backgrounds.find(
                    (background) =>
                      background.color === babyPageBD.background_color
                  )
                : undefined;

              const layout = babyPageBD.layout;
              const layoutRightSide = babyPageBD.layout_right;

              const labelUrlPhoto: BpPhotoDB = babyPageBD.photos.find(
                (p: BpPhotoDB) => !!p.label_url
              );

              let label_url_file = !!labelUrlPhoto
                ? labelUrlPhoto.label_url
                : undefined;

              // Use this to test big contents
              // let label_url_file =
              //   'https://babypage-prod.s3.amazonaws.com/public/10704/photos/5a9d597a5.png';

              let label_url = !!label_url_file
                ? await getBase64FromFileUrl(
                    label_url_file,
                    `${BASE_URL}api/avoid-cors?url=`
                  )
                : undefined;
              let photos: SaveBabyPagePhoto[] = [];
              let photosRightSide: SaveBabyPagePhoto[] = [];

              let placeHolderB64 = await getPlaceholderB64();

              if (!!babyPageBD.photos && !!babyPageBD.photos.length) {
                for (let i = 0; i < babyPageBD.photos.length; i++) {
                  photos.push(
                    await getPhotoFile(
                      cloneDeep(babyPageBD.photos[i]),
                      layout,
                      i,
                      !!babyPageBD.version ? babyPageBD.version : "web"
                    )
                  );
                }
                for(let photo of photos) {
                  if(photo.base64 === placeHolderB64) {
                    photo.missing = true;
                  }
                  photo.side = "left"
                }

                if (!!babyPageBD.photos_right && !!babyPageBD.photos_right.length) {
                  for (let i = 0; i < babyPageBD.photos_right.length; i++) {
                    photosRightSide.push(
                      await getPhotoFile(
                        cloneDeep(babyPageBD.photos_right[i]),
                        layoutRightSide,
                        i,
                        !!babyPageBD.version ? babyPageBD.version : "web"
                      )
                    );
                  }
               } 
               for(let photo of photosRightSide) {
                if(photo.base64 === placeHolderB64) {
                  photo.missing = true;
                }
                 photo.side = "right"
               }

              }  
              else {
                photos = cloneDeep(initialBabyPage.photos);
              }


              // Changes to adjust phoonly babypages that had internal labels, before internal label option was removed 
              babyPageBD.label_left_position = babyPageBD.label_left_position === "top-right" ? "top-left" :
                                               babyPageBD.label_left_position === "bottom-right" ? "bottom-left" :
                                               babyPageBD.label_left_position
                                               
              babyPageBD.label_right_position = babyPageBD.label_right_position === "top-left" ? "top-right" :
                                                babyPageBD.label_right_position === "bottom-left" ? "bottom-right" :
                                                babyPageBD.label_right_position


              let labelPositionLeft, labelPositionRight;
              switch (babyPageBD.label_left_position) {
                case "top-left":
                  labelPositionLeft = {
                    display: "flex", justifyContent: "flex-start", alignItems: "flex-start",
                  }
                  break;
                case "top-right":
                  labelPositionLeft = {
                    display: "flex", justifyContent: "flex-end", alignItems: "flex-start",
                  }
                  break;
                case "top-center":
                    labelPositionLeft = {
                      display: "flex", justifyContent: "center", alignItems: "flex-start",
                    }
                    break;
                case "bottom-right":
                    labelPositionLeft = {
                      display: "flex", justifyContent: "flex-end", alignItems: "flex-end",
                    }
                    break;
                case "bottom-left":
                    labelPositionLeft = {
                      display: "flex", justifyContent: "flex-start", alignItems: "flex-end",
                    }
                    break;
                case "bottom-center":
                    labelPositionLeft = {
                      display: "flex", justifyContent: "center", alignItems: "flex-end",
                    }
                    break;
                case "":
                  labelPositionLeft = {
                    display: "none",
                  }
              }

              switch (babyPageBD.label_right_position) {
                case "top-left":
                  labelPositionRight = {
                    display: "flex", justifyContent: "flex-start", alignItems: "flex-start",
                  }
                  break;
                case "top-right":
                  labelPositionRight = {
                    display: "flex", justifyContent: "flex-end", alignItems: "flex-start",
                  }
                  break;
                case "top-center":
                    labelPositionRight = {
                      display: "flex", justifyContent: "center", alignItems: "flex-start",
                    }
                  break;
                case "bottom-left":
                    labelPositionRight = {
                      display: "flex", justifyContent: "flex-start", alignItems: "flex-end",
                    }
                  break;
                case "bottom-right":
                    labelPositionRight = {
                      display: "flex", justifyContent: "flex-end", alignItems: "flex-end",
                    }
                  break;
                case "bottom-center":
                    labelPositionRight = {
                      display: "flex", justifyContent: "center", alignItems: "flex-end",
                    }
                  break;
                case "":
                  labelPositionRight = {
                    display: "none",
                  }
              }
              let allPhotos = [
                ...photos,
                ...photosRightSide
              ]
              let hasMissing = allPhotos.some((item) => {
                return item.missing
              })

              let isIosWeightAnswer = babyPageBD.answers !== null ? babyPageBD.answers.filter((item:any) => item.type === 'height' && babyPageBD.version === 'ios'&& item.answerString.length > 1 ) : false
              let separateQuestions : any = []

              if(!!isIosWeightAnswer.length) {
                let weight = 0;
                separateQuestions =  isIosWeightAnswer[0].answerString.map((item:any, index:number) => {

                if (item !== '-') {
                  if(index === 0 ) {
                    return {
                      answer_string: item.split(" "),
                      answer: isIosWeightAnswer[0].answers,
                      position: isIosWeightAnswer[0].position,
                      question_id: isIosWeightAnswer[0].question_id,
                      type: "height",
                    }
                  } else if (index === 1) {
                    weight+= parseInt(item) * 16

                    if(isIosWeightAnswer[0].answerString[2] !== '-') {
                      return null
                    } 
                    else {
                      return  {
                        answer_string: weight.toString().split(" "),
                        answer: `${babySelected.baby_first_name} is ${(weight)} lbs `,
                        position: isIosWeightAnswer[0].position,
                        question_id: isIosWeightAnswer[0].question_id,
                        type: "weight",
                      }
                    } 
                  } else {
                    weight+= parseInt(item)
                    return {
                      answer_string: weight.toString().split(" "),
                      answer: `${babySelected.baby_first_name} is ${(weight - (weight %= 16)) / 16} lbs ${weight %= 16} oz`,
                      position: isIosWeightAnswer[0].position,
                      question_id: isIosWeightAnswer[0].question_id,
                      type: "weight",
                    }
                  } 
                }                       
                }).filter((item: any) => item != null)
              }

              let completedAnswers = !!babyPageBD.answers && !!babyPageBD.answers.length ?
              babyPageBD.answers.map((answer: any) => {
                return {
                  answer_string: answer.answerString,
                  answer: answer.answers,
                  position: answer.position,
                  question_id: answer.question_id,
                  type: answer.type.toLowerCase(),
                }
              }) : []

              let filteredAnswers = completedAnswers.filter((item : any) => item.type !== 'height')
              completedAnswers = !!isIosWeightAnswer.length ? [...filteredAnswers, ...separateQuestions] : [...completedAnswers, ...separateQuestions]           

              const bp: SaveBabyPage = {
                id,
                photos,
                photosRightSide,
                answers:
                  !!babyPageBD.answers && !!babyPageBD.answers.length
                    ? completedAnswers
                    : [],
                borderSelected,
                illustrationSelected,
                backgroundSelected,
                font: babyPageBD.font,
                textAlign: babyPageBD.font_alignment,
                layout,
                layoutRightSide,
                somethingChanged: true,
                thumbnail: babyPageBD.thumbnail,
                thumbnail_content: babyPageBD.thumbnail_content,
                label_url,
                label_url_file,
                label_left_text: babyPageBD.label_left_text,
                label_right_text: babyPageBD.label_right_text,
                label_left_position: babyPageBD.label_left_position,
                label_right_position: babyPageBD.label_right_position,
                labelStatePositionLeft: labelPositionLeft,
                labelStatePositionRight: labelPositionRight,
                placeholder: !!placeHolderB64 ? placeHolderB64 : undefined,
                isEdit: true,
                hasMissing: hasMissing,
                font_id: babyPageBD.font_id,
                child_id: babyPageBD.child_id 
              };
              setMilestoneAsFirstLine(!!babyPageBD.milestone_date_as_first_line);
              setBabyPage(bp);

               // cleaning names of photos by removing the url
               const cleanPhotos = babyPageBD.photos;
               const cleanPhotosRight = babyPageBD.photos_right
 
               for(let photo of cleanPhotos) {
                 let name = photo.name.split('/photos/')[1]
                 photo.name = name
               }
               for (let photo of cleanPhotosRight) {
                 let name = photo.name.split('/photos/')[1]
                 photo.name = name
               }
 
               setPhotosToBeSaved({
                 photos: cleanPhotos,
                 photos_right: cleanPhotosRight
               });
               setTriggerPhotoUpdate(false);
 

              let i = -1;
              let holiday = false;
              const searchMilestones = milestones.find((milestone) => {
                i = milestone.all_milestones.findIndex(
                  (e) => e.id === babyPageBD.milestone_id
                );

                if (i === -1) {
                  i = milestone.holiday_milestones.findIndex(
                    (e) => e.id === babyPageBD.milestone_id
                  );
                  holiday = i !== -1;
                }

                return i !== -1;
              });
              const isHoliday = babyPageBD.age_weeks === -999
              const isFirstEvent = babyPageBD.age_weeks === -800
              const isPregnancy = babyPageBD.age_weeks === -700

              const heightAnswer = babyPageBD.answers !== null ? babyPageBD.answers.filter((item: { type: string; }) => item.type === "height") : "";
              const weightAnswer = babyPageBD.answers !== null ? babyPageBD.answers.filter((item: { type: string; }) => item.type === "weight") : "";
              
              const exactMilestone = isHoliday ? searchMilestones?.holiday_milestones.filter(item => babyPageBD.milestone_id === item.id) :  
                                     isFirstEvent ?  searchMilestones?.first_event_milestones.filter(item => babyPageBD.milestone_id === item.id) :
                                     isPregnancy ? searchMilestones?.pregnancy_milestones.filter(item => babyPageBD.milestone_id === item.id) :
                                                    searchMilestones?.all_milestones.filter(item => babyPageBD.milestone_id === item.id);
              const babyID = babyPageBD.child_id;
              const event = exactMilestone![0].title_name;
              const exactBaby = baby.babies.filter(baby => babyID === baby.id )
              const quarantineMilestoneID = PRODUCTION ? 195 : 228;
              let growthEntry = {...entry};
              let babyBirth = moment(
                exactBaby[0].baby_birthdate.replace(" 00:00:00", "")
              );

              if (
                !!exactMilestone![0].age_months &&
                parseInt(exactMilestone![0].age_months) > 0
              ) {

                babyBirth = babyBirth.add(
                  parseInt(exactMilestone![0].age_months),
                  "months"
                );
                growthEntry = checkIfEntryExist(babyBirth)

              } else if (event.toLowerCase() === "custom page") {
                babyBirth = moment();
              } else if (
                !!exactMilestone![0].age_weeks &&
                !isNaN(exactMilestone![0].age_weeks) &&
                exactMilestone![0].age_weeks > 0
              ) {

                babyBirth = exactMilestone![0].id === quarantineMilestoneID ? babyBirth : babyBirth.add(exactMilestone![0].age_weeks, "weeks");
                growthEntry = checkIfEntryExist(babyBirth)
              }
              

              if(babyPageBD.answers !== null) {
                if(!!heightAnswer.length || weightAnswer.length) {

                  setEntry({
                    ...growthEntry,
                    baby_id: babyPageBD.child_id,
                    height : heightAnswer[0] !== undefined ? parseInt(heightAnswer[0].answerString[0]) : 0,
                    weight_lbs: weightAnswer[0] !== undefined ? Math.floor((parseInt(weightAnswer[0].answerString[0]) / 16)): 0,
                    weight_oz: weightAnswer[0] !== undefined ? parseInt(weightAnswer[0].answerString[0]) % 16 : 0
                   })
                }
                else {
                  setEntry({...growthEntry, baby_id: babyPageBD.child_id});
                }
              
              }  else {
                setEntry({...growthEntry, baby_id: babyPageBD.child_id});
              }
             
              if (i !== -1 && !!searchMilestones) {
                setMilestone(
                  searchMilestones[
                    holiday ? "holiday_milestones" : "all_milestones"
                  ][i]
                );

                sagaMiddleware.run<any>(
                  fetchQuestions,
                  searchMilestones[
                    holiday ? "holiday_milestones" : "all_milestones"
                  ][i].title_name,
                  (questionsRes: QuestionItem[]) => {
                    if (!!questionsRes && !!questionsRes.length) {
                        for(let question of questionsRes) {                     
                          let questionsFixed = [...question.answers]
                            if(!!question.multiple_choice) {
                              let matchedAnswerQuestion = bp.answers.find((answer: any) => answer.question_id === question.id )
                              if(!!matchedAnswerQuestion) {
                                  for (let answerString of matchedAnswerQuestion.answer_string) {
                                    let missingAnswer = !question.answers.includes(answerString)
                                    if(missingAnswer) {
                                      questionsFixed.push(answerString)                                   }                                 
                                }
                                question.answers = [...questionsFixed]
                              }
                            }
                        }
                      setQuestions(questionsRes);
                    }
                  }
                );
              } else {
                Modal.error({
                  title: "Error",
                  content: "Milestone not found",
                });
                history.push("/");
              }

              setControl({
                valid: true,
                step: !!missingContent && !!photosRightSide.length ? 4 : !!missingContent ? 3 :  4,
              });
            } catch (error) {
              Modal.error({
                title: "Error",
                content: error.message
                  ? error.message
                  : "Error loading BabyPage",
              });
              history.push("/");
            }

            setLoaded(true);
            setLoading(false);
          }
        );
      }
      return;
    } else if (!!babySelected) {
      const milestoneId = queryString.parse(location.search).milestoneId; // If is create
      if (!!milestoneId) {
        const milestoneBaby = milestones.find(
          (milestone) => milestone.id === babySelected.id
        );
        if (!!milestoneBaby) {
          const searchMilestone = [
            ...milestoneBaby.all_milestones,
            ...milestoneBaby.holiday_milestones,
          ].find(
            (milestone) => milestone.id === parseInt(milestoneId.toString())
          );

          if (!!searchMilestone) {
            setMilestone(searchMilestone);

            sagaMiddleware.run<any>(
              fetchQuestions,
              searchMilestone.title_name,
              (questionsRes: QuestionItem[]) => {
                if (!!questionsRes && !!questionsRes.length) {
                  setQuestions(questionsRes);
                }
              }
            );

            let babyBirth = moment(
              babySelected.baby_birthdate.replace(" 00:00:00", "")
            );

            const event = searchMilestone.title_name;
            const ageWeeks = searchMilestone.age_weeks || 0;
            const isBirth = event === "Birth Announcement";

            // Setting id for quarantine milestone depending on the enviroment 
            // This ID should never change at db if it changes then this hardcoded
            // options should change.
            const quarantineMilestoneID = PRODUCTION ? 195 : 228;

            let ordinal = "";
            let growthEntry = {...entry};

            if (
              ageWeeks < 0 &&
              searchMilestone.active_date !== "0000-00-00" &&
              !isBirth
            ) {
              babyBirth = moment(searchMilestone.active_date);
              ordinal = addSuffix(parseInt(babyBirth.format("D")));
            } else if (!isBirth) {
              if (
                !!searchMilestone.age_months &&
                parseInt(searchMilestone.age_months) > 0
              ) {
                babyBirth = babyBirth.add(
                  parseInt(searchMilestone.age_months),
                  "months"
                );
                growthEntry = checkIfEntryExist(babyBirth)
              } else if (event.toLowerCase() === "custom page") {
                babyBirth = moment();
              } else if (
                !!searchMilestone.age_weeks &&
                !isNaN(searchMilestone.age_weeks) &&
                searchMilestone.age_weeks > 0
              ) {
                babyBirth = searchMilestone.id === quarantineMilestoneID ? babyBirth : babyBirth.add(searchMilestone.age_weeks, "weeks");
                growthEntry = checkIfEntryExist(babyBirth)
              }
              setEntry({
               ...growthEntry
              })
              ordinal = addSuffix(parseInt(babyBirth.format("D")));
            } else {
              if (
                !!babySelected &&
                !!babySelected.heights &&
                !!babySelected.weights &&
                !!babySelected.heights.length &&
                !!babySelected.weights.length
              ) {
                // Add Birth Answers
                const firstHeight = babySelected.heights[0];
                const firstWeight = babySelected.weights[0];

                const oz = parseInt(firstWeight.oz) % 16;
                const lbs = ~~(parseInt(firstWeight.oz) / 16);

                let preparedAnswers: any[] = [];
                if (isBirth) {
                  let firstAnswer = `${
                    !!babySelected.baby_birthdate
                     ? `${moment(babySelected.baby_birthdate).format("MMMM Do, YYYY")}`
                     : ""
                  }`

                  if (firstAnswer) {
                    preparedAnswers = preparedAnswers.concat({
                      answer: firstAnswer,
                      answer_string: [firstAnswer],
                      position: 1,
                      question_id: -1002,
                      type: "custom",
                    });
                  }
                  let secondAnswer = `${
                    !!babySelected.baby_birth_time
                      ? `${babySelected.baby_birth_time}`
                      : ""
                  }`;

                  if (secondAnswer) {
                    secondAnswer = secondAnswer.charAt(0) === "0" ? 
                                   secondAnswer.slice(1, secondAnswer.length) : 
                                   secondAnswer
                                   
                    preparedAnswers = preparedAnswers.concat({
                      answer: secondAnswer,
                      answer_string: [secondAnswer],
                      position: 2,
                      question_id: -1003,
                      type: "custom",
                    });
                  }

                  let weightAnswer = `${lbs >= 0 ? `${lbs} lbs` : ""}${
                    oz >= 0 ? `${oz >= 0 ? " " : ""}${oz} oz,` : ""
                  }`;
                  let heightAnswer = `${
                    !!firstHeight.height && parseFloat(firstHeight.height) > 0
                      ? ` ${firstHeight.height} Inches`
                      : ` ${parseFloat(firstHeight.height).toFixed(1)} Inches`
                  }`;
                  let thirdAnswer : string;
                  if (parseFloat(firstHeight.height) <= 0 && (lbs + oz) <= 0) {
                    thirdAnswer = ""
                  } else {
                    thirdAnswer = weightAnswer + heightAnswer
                  }
                   
                  if (thirdAnswer) {
                    preparedAnswers = preparedAnswers.concat({
                      answer: thirdAnswer,
                      answer_string: [thirdAnswer],
                      position: 3,
                      question_id: -999,
                      type: "custom",
                    });
                  }
                  let fourthAnswer = `${
                    !!babySelected.baby_birthplace
                      ? `${babySelected.baby_birthplace}`
                      : ""
                  }`;

                  if (fourthAnswer) {
                    preparedAnswers = preparedAnswers.concat({
                      answer: fourthAnswer,
                      answer_string: [fourthAnswer],
                      position: 4,
                      question_id: -1001,
                      type: "custom",
                    });
                  }
                } else {
                  let answer = `${lbs > 0 ? `${lbs} lbs` : ""}${
                    oz > 0 ? `${oz > 0 ? " and " : ""}${oz} oz` : ""
                  }${
                    !!firstHeight.height ? ` ${firstHeight.height} Inches` : ""
                  }${
                    !!babySelected.baby_birthplace
                      ? ` ${babySelected.baby_birthplace}`
                      : ""
                  }`;

                  preparedAnswers = preparedAnswers.concat({
                    answer,
                    answer_string: [answer],
                    position: 1,
                    question_id: -999,
                    type: "custom",
                  });
                }

                setBabyPage((state) => ({
                  ...state,
                  answers: preparedAnswers,
                }));
              }

              ordinal = addSuffix(parseInt(babyBirth.format("D")));
            }
           
            let initialTitle;
            let milestoneTitle;
            let today = moment().format("MMMM Do, YYYY");
            if(!draftBabyPage.title) {
              if(ageWeeks === -800){
                today = moment().format("YYYY-MM-DD 00:00:00")
                // Event Milestone 
                initialTitle = "";
                milestoneTitle = today;
              } else if(ageWeeks === -700 || ageWeeks === -2 ){
                if(ageWeeks===-700){
                  //  Pregnancy Milestone
                  initialTitle = moment(babySelected.baby_birthdate)
                                  .subtract(
                                    STANDARD_WEEKS_FOR_PREGNANCY_MILESTONE - searchMilestone.pregnancy_weeks, 
                                    'week'
                                  ).format("MMMM Do, YYYY");
                    milestoneTitle = initialTitle
                }else
                // Photo Only Milestone
                  
                  initialTitle = today;
                  milestoneTitle = initialTitle
                  
              } else if(ageWeeks === -1) {
                // Custom Milestone
                milestoneTitle = moment().format("YYYY-MM-DD 00:00:00");
                initialTitle = today;
              }else{
                // MileStone Date
                initialTitle = babyBirth.format(`MMMM [${ordinal},] YYYY`);
                milestoneTitle = initialTitle
              }
              setMilestoneDate(milestoneTitle);
              setSubTitle(initialTitle);
              setTitle(
                ageWeeks < 0
                  ? event.toLowerCase() === "custom page"
                    ? "Custom Page"
                    : ageWeeks === -800
                    ? `${babySelected.baby_first_name}${
                        babySelected.baby_first_name.toLocaleLowerCase()[
                          babySelected.baby_first_name.length - 1
                        ] === "s"
                          ? "'"
                          : "'s"
                      } ${event}`
                    : event
                  : ageWeeks === 0
                  ? `${babySelected.baby_first_name}${
                      !!babySelected.baby_middle_name
                        ? ` ${babySelected.baby_middle_name}`
                        : ""
                    } ${babySelected.baby_last_name}`
                  : searchMilestone.id === quarantineMilestoneID ? event : `${babySelected.baby_first_name} at ${event}`
              );
            } else if(!!draftBabyPage && !!draftBabyPage.title && !!draftBabyPage.subTitle ) {
              setTitle(draftBabyPage.title)
              setSubTitle(draftBabyPage.subTitle)
            }      
            setLoaded(true);
            return;
          }
        }
      }
    }

    history.push("/");
  }, [
    milestones,
    location.search,
    babySelected,
    history,
    borders,
    illustrations,
    babyPage,
    loaded,
  ]);

  // Automatic select first border available
  useEffect(() => {
    if (
      borders &&
      !!borders.length &&
      !babyPage.borderSelected &&
      !draftBabyPage.borderSelected
    ) {
      setBabyPage((state) => ({
        ...state,
        borderSelected:
          !!user.user_level && user.user_level === 1
            ? borders[0]
            : borders.filter((b) => !b.is_plus)[0],
      }));
    }
  }, [borders, babyPage.borderSelected, user.user_level]);

  /**
   * Update Photos or Layout Selected
   * @param {SaveBabyPagePhoto[]} photos  // Selected Photos
   * @param {Layout} layout               // Selected Layout
   * @param {boolean} forceRecrop         // If should force recrop photos (layout change)
   */
  const setPhotos = useCallback(
    async (
      photos: SaveBabyPagePhoto[],
      layout: Layout,
      forceRecrop: boolean = false,
      step: number = control.step,
    ) => {
      const layoutInfo = getLayoutInfos(layout);
        if (babyPage.layout !== layout && step === 0) {
          photos = photos.filter((photo) => photo.base64_cropped !== "");
          while (layoutInfo.length < photos.length) {
            photos.splice(photos.length - 1, 1);
          }
          while (layoutInfo.length > photos.length) {
            photos.push({
              base64_cropped: "",
            });
          }
  
          photos = await checkPhotoLayouts(
            photos,
            layout,
            layoutInfo,
            true,
            babyPage.layout
          );
        } else if (isPhotoOnly && step === 1) {
        if (babyPage.layoutRightSide !== layout) {
          photos = photos.filter((photo) => photo.base64_cropped !== "");
          while (layoutInfo.length < photos.length) {
            photos.splice(photos.length - 1, 1);
          }
          while (layoutInfo.length > photos.length) {
            photos.push({
              base64_cropped: "",
            });
          }
  
          photos = await checkPhotoLayouts(
            photos,
            layout,
            layoutInfo,
            true,
            babyPage.layoutRightSide
          );
        }
      } else if (forceRecrop) {
        photos = await checkPhotoLayouts(photos, layout, layoutInfo);
      }

      if (isPhotoOnly && control.step === 1) {
        photos = photos.map(photo => {
          photo.side = 'right';
          return photo
        })
        setBabyPage((old) => ({
          ...old,
          font: "Montserrat-Regular",
          photosRightSide: photos,
          layoutRightSide: layout,
          somethingChanged: true,
          label_left_position: babyPage.label_left_position ? babyPage.label_left_position : "bottom-left",
          label_right_position: babyPage.label_right_position ? babyPage.label_right_position : "bottom-right"
        }));
      } 
      else if (!isPhotoOnly || (isPhotoOnly && control.step === 0)) {
        if(isPhotoOnly) {
        photos = photos.map(photo => {
          photo.side = 'left';
          return photo
        })
      }
        setBabyPage((old) => ({
          ...old,
          photos,
          layout,
          somethingChanged: true,
        }));
      }

      setControl((state) => ({
        ...state,
        valid:
          photos.filter((photo) => photo.base64_cropped !== "").length ===
          layoutInfo.length,
      }));

      if(isPhotoOnly && control.step === 1) {
        setControl((state) => ({
          ...state,
          valid:
            photos.filter((photo) => photo.base64_cropped !== "").length ===
            layoutInfo.length,
        }));
      }
    },
    [babyPage, milestone, control.step]
  );

  // Set Answers Information
  const setAnswers = useCallback((answers: QuestionAnswer[]) => {
    setBabyPage((state) => ({
      ...state,
      answers,
      label_url: undefined,
      thumbnail_content: undefined,
    }));
  }, []);

  // Set Font Information
  const setFont = useCallback((font: string, font_id: number) => {

    setBabyPage((state) => ({
      ...state,
      font,
      label_url: undefined,
      thumbnail_content: undefined,
      font_id,
    }));
  }, []);

  // Set Text Align Information
  const setTextAlign = useCallback((textAlign: "start" | "center" | "end") => {
    setBabyPage((state) => ({
      ...state,
      textAlign,
      label_url: undefined,
      thumbnail_content: undefined,
    }));
  }, []);

  // Set Border Information
  const setBorder = useCallback((borderSelected: Border) => {
    setBabyPage((state) => ({
      ...state,
      borderSelected,
      label_url: undefined,
      thumbnail_content: undefined,
    }));
  }, []);

  // Set Illustration Information
  const setIllustration = useCallback(
    (illustrationSelected: Illustration | undefined) => {
      setBabyPage((state) => ({
        ...state,
        illustrationSelected,
        label_url: undefined,
        thumbnail_content: undefined,
      }));
    },
    []
  );

  // Set Background Information
  const setBackground = useCallback(
    (backgroundSelected: Background | undefined) => {
      setBabyPage((state) => ({
        ...state,
        backgroundSelected,
        label_url: undefined,
        thumbnail_content: undefined,
      }));
    },
    []
  );

  const saveBeforeUpgradeToPlus = () => {
    const milestoneId = queryString.parse(location.search).milestoneId; // If is create
    const babyPageObject = { ...babyPage, milestoneId, textAnswers, title, subTitle };
    sagaMiddleware.run<any>(setDraftBabyPage, babyPageObject);
    history.push("/cart/subscription/yearly");
  };

  const sendToUpgradeToPlus = () => {
    history.push("/cart/subscription/yearly");
  }

  const confirmSaveFeature = useCallback(async () => {
    const freeBorderSelected = borders.filter((border) => !border.is_plus);
    const freeFontSelected = fonts.filter((font) => !font.plus);
    const isFreeLayout = babyPage.layout === "layout1A" || babyPage.layout === "layout2A" || babyPage.layout === "layout2B"
    let newPhoto;

    if(babyPage.photos.length <= 2 && isFreeLayout) {
      newPhoto = babyPage.photos
    } else {
      newPhoto = babyPage.photos.splice(0, 1);
    }

    setPhotos(newPhoto, initialBabyPage.layout);
    setBorder(freeBorderSelected[0]);
    setIllustration(undefined);
    setBabyPage((state) => ({
      ...state,
      layout: isFreeLayout ? babyPage.layout : initialBabyPage.layout,
      font: selectedPlus.font ? freeFontSelected[0].name : babyPage.font,
      font_id: selectedPlus.font ? freeFontSelected[0].id : babyPage.font_id
    }));
    setPlusOpen(false);
    setSaveWithoutPlus(true);
  }, [babyPage, selectedPlus]);

  // Save free features
  const saveFreeFeatures = async () => {
    confirm({
      title:(
        <>
          <h3 className="confirmation-question">Are you sure?</h3>
          <p>Do you want to remove all PLUS features of your BabyPage and save the free version?</p>
        </>
       ),
      okText: "Save",
      okType: "primary",
      cancelText: "Cancel",
      width: 300,
      icon: "warning",
      async onOk() {
        await confirmSaveFeature();
      },
    });
  };

  const ageWeeks = useMemo(() => (!!milestone ? milestone.age_weeks : 0), [
    milestone,
  ]);

  // Separate what is Question and Height/Weight part
  const [cleanedQuestions, heightQuestions] = useMemo(() => {
    if (!!babySelected) {
      const cleanedQuestions: QuestionItem[] = [];
      const heightQuestions: QuestionItem[] = [];

      cleanQuestions(questions, babySelected).forEach((e) => {
        if (e.id === null) heightQuestions.push(e);
        else cleanedQuestions.push(e);
      });

      // age_weeks=-800 means first event milestone
      // age_weeks=-700 means pregnancy milestone
      if (
        milestone?.age_weeks === -800 ||
        (milestone?.age_weeks === -700 && !!milestone.date_required)
      ) {
        cleanedQuestions.push({
          id: milestone?.age_weeks === -800 ? -800 : -700,
          title: "When did this milestone take place?",
          question: "When did this milestone take place?",
          export: "[ANSWER]",
          answers: [""],
          multiple_choice: 3,
          milestone_id: milestone?.id || 0,
          order: cleanedQuestions.length + 1,
          created_at: "",
          deleted_at: "",
          updated_at: "",
          type: milestone?.age_weeks === -800 ? "first_event" : "pregnancy",
        });
      }

      return [cleanedQuestions, heightQuestions];
    }
    return [[], []];
  }, [questions, babySelected, milestone]);

  const setCustomAnswers = useCallback(
    (changedAnswers: SimplifiedAnswer[]) =>
      setCustomAnswersHelper(setBabyPage, changedAnswers),
    []
  );

  const setSortedAnswers = useCallback(
    (orderedAnswers: SimplifiedAnswer[]) =>
      setSortedAnswersHelper(setBabyPage, orderedAnswers),
    []
  );

  const addCustomAnswer = useCallback(
    (answer: string) => addCustomAnswerHelper(setBabyPage, answer),
    []
  );

  const removeAnswer = useCallback((index: number) => {
    setBabyPage((state) => ({
      ...state,
      answers: ((): QuestionAnswer[] => {
        const newAnswers = cloneDeep(state.answers);
        newAnswers.splice(index, 1);
        return newAnswers;
      })(),
      label_url: undefined,
    }));
  }, []);
  let repair: any = queryString.parse(location.search).repair;
  const getTextMessageByStep = () => {
    const { step: currentStep } = control;
    let repair: any = queryString.parse(location.search).repair;

    if (currentStep === 1 && !isPhotoOnly) {
      return "SKIP";
    } else if (currentStep === 4 || (currentStep === 2 && isPhotoOnly)) {
      if(repair){
        return "REPAIR";
      }
      return "FINISH";
    }
    return "NEXT";
  };

  const answersArray: SimplifiedAnswer[] = useMemo(() => {
    const answersArray: SimplifiedAnswer[] = [];

    if (!babySelected) return answersArray;

    const answers = babyPage.answers;

    if (!answers || !answers.length) return answersArray;

    const [height, weight] = [
      answers.find((a) => a.type === "height"),
      answers.find((a) => a.type === "weight"),
    ];

    if (
      height &&
      !!height.answer_string.length &&
      weight &&
      !!weight.answer_string.length
    ) {
      const totalWeight = parseInt(weight.answer_string[0]);
      const [lbs, oz] = [~~(totalWeight / 16), totalWeight % 16];
      const growthAnswer = exportGrowthAnswer(
        parseFloat(height.answer_string[0]),
        lbs,
        oz,
        babySelected
      );
      if (!!growthAnswer)
        answersArray.push({
          answer: growthAnswer,
          question_id: null,
          type: "height_weight",
        });
    }  

    // age_weeks=-800 means first event milestone
    // age_weeks=-700 means pregnancy milestone
    if (milestone?.age_weeks === -800 || milestone?.age_weeks === -700) {
      const firstEventDate = answers.filter(
        (answer) =>
          (answer.type === "first_event" && !!answer.answer_string.length) ||
          (answer.type === "pregnancy" && !!answer.answer_string.length)
      );

      if (firstEventDate[0]) {
        setSubTitle(
          exportAnswer(
            firstEventDate[0].answer_string,
            firstEventDate[0].question_export || firstEventDate[0].answer
          )
        );
      }
    }
    const hasHeightAndWeight = answersArray.some((answer) => answer.type === "height_weight" )


    const filteredAnswers = hasHeightAndWeight ?
   ( answers.filter(
      (answer) =>
        (answer.type === "question" ||
          answer.type === "custom" ||
          answer.type === "first_event" ||
          answer.type === "pregnancy" ||
          answer.type === "height_weight") &&
        !!answer.answer_string.length
    )) : ( answers.filter(
      (answer) =>
        (answer.type === "question" ||
          answer.type === "custom" ||
          answer.type === "first_event" ||
          answer.type === "pregnancy" ||
          answer.type === "height" ||
          answer.type === "weight" ||
          answer.type === "height_weight") &&
        !!answer.answer_string.length
    ))

    //we should not show answers of type first event
    return answersArray.concat(
      filteredAnswers.map((answer) =>
          answer.type === "custom"
            ? {
                answer: answer.answer,
                question_id: answer.question_id,
                type: answer.type,
              }
            : {
                answer: capitalizeText(
                  exportAnswer(
                    answer.answer_string,
                    answer.question_export || answer.answer
                  )
                ),
                question_id: answer.question_id,
                type: answer.type,
                answer_string: answer.answer_string
              }
        )
    );
  }, [babyPage.answers, babySelected, milestone]);

  // Mount Text Answer (Content Part)
  const textAnswers: string = useMemo(() => {
    if (answersArray.length === 0) setIsTruncated(false);

    if (milestone?.title === "Birth Announcement") {
      return answersArray.map((a) => a.answer).join("\n");
    }

    //we should not show answers of type first event
    const result = answersArray
      .filter(
        (a) => a.answer && a.type !== "first_event" && a.type !== "pregnancy"
      )
      .map((a) => a.answer)
      .join(" ");

    if (result.trim().length === 0) setIsTruncated(false);

    return result;
  }, [answersArray, milestone, babyPage.answers]);

  let responsesImageLeft : any, responsesImageRight : any;
  const [photosTobeSaved, setPhotosToBeSaved] = useState({});
  const [triggerPhotoUpdate, setTriggerPhotoUpdate] = useState(false)
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let allPhotos = isPhotoOnly ? [
      ...babyPage.photos,
      ...babyPage.photosRightSide
    ] : [
      ...babyPage.photos,
    ]
    if(control.step === 4) {
    let hasMissingImages = allPhotos.some((item) => {
      return item.base64 === babyPage.placeholder
    })
      if(hasMissingImages) {
        setDisabled(true)
      } else if (!hasMissingImages)  {
        setDisabled(false)
      }
    }
  }, [JSON.stringify(babyPage.photos), JSON.stringify(babyPage.photosRightSide), control.step])
  useEffect(() => {
    setBabyPage((old) => ({
     ...old,
     photosUploaded:2,
   }))
  },[triggerPhotoUpdate])

  useEffect(() => {
    if(isPhotoOnly && control.step === 1) {
      setControl( (old) => ({
        ...old,
        valid: false
      }));
    }
  }, [control.step])

 useEffect(() => {
   setBabyPage((old) => ({
     ...old,
     photosUploaded:2,
   }))
 },[photosTobeSaved]) 

 const [qIndex, setQIndex] = useState(0);
 const [customAnswer, setCustomAnswer] = useState("");
 const hasQuestions = useMemo(
  () => !!cleanedQuestions && !!cleanedQuestions.length && !!cleanedQuestions[0],
  [questions]
);

 const answer = useMemo(
  () =>
    hasQuestions
      ? babyPage.answers.find((e) => e.question_id === cleanedQuestions[qIndex].id)
      : undefined,
  [babyPage.answers, qIndex, questions, hasQuestions]
);
const checked = useMemo(() => (answer ? answer.answer_string : []), [answer]);

const changeAnswer = useCallback(
  (answer: QuestionAnswer) => {
    if (babyPage.answers.length === 0) return setAnswers([answer]);
    const index = babyPage.answers.findIndex((e) =>
      e.question_id !== null && answer.question_id !== null
        ? e.question_id === answer.question_id
        : e.type === answer.type
    );
    if (index === -1) return setAnswers([...babyPage.answers, answer]);
    else if (
      babyPage.answers[index].answer_string.toString() !==
      answer.answer_string.toString()
    ) {
      const newAnswers = [...babyPage.answers];
      newAnswers[index] = answer;
      setAnswers(newAnswers);
    }
  },
  [babyPage.answers, setAnswers]
);

const addQuestion = (question: QuestionItem) => {
  if (customAnswer === "") return;
  const otherAnswer = customAnswer;
  setCustomAnswer("");

  const answer_string = [...checked, otherAnswer] as string[];
  const newAnswer: QuestionAnswer = answer
    ? { ...answer, answer_string, answer: multipleAnswers(question.export, answer_string) }
    : {
        question_id: question.id,
        position: !!question.order ? question.order : 0,
        answer_string,
        answer: question.export,
        type: question.type,
      };

  changeAnswer(newAnswer);
  setQuestions((cleanedQuestions: QuestionItem[]) =>
    cleanedQuestions.map((q: QuestionItem) =>
      q.id === question.id
        ? { ...q, answers: [...q.answers, otherAnswer] }
        : q
    )
  );
}

 const uploadPhotosToS3 = useCallback( async () => {
  setSaveStep("genImg");
  setDisabled(true);

  let cPhotos: HTMLCanvasElement | null = null;
  let cContent: HTMLCanvasElement | null = null;
  let label_url = !!babyPage.label_url ? babyPage.label_url : undefined;

    try {
      const photoGrid = document.getElementById("photo-grid-bp");
      if (!!photoGrid ) {
        cPhotos = await html2canvas(photoGrid, { ...highRes, scale: 1 });

        if (isPhotoOnly) {
          cContent = null;
        } 
      } 
     
      if(!!cPhotos &&  !!milestone && !!babySelected) {
        // First send images to S3 and Get URL
        var imageCPhoto = await canvasToBlobPromise(cPhotos);


        const photosRightToS3: FileUploadS3[] = [
          {
            image: imageCPhoto,
            folder: "bps_content",
          },
          ...(babyPage.photosRightSide
            .filter((photo) => !!photo.file)
            .map((photo) => ({
              image: photo.file,
              folder: "photos",
              baby_id: babySelected.id,
              gallery_image: photo.isLocal ? null : photo.name?.split('/').slice(-1)[0],
            })) as FileUploadS3[]),
        ];
          if (control.step === 1 && isPhotoOnly) {
          sagaMiddleware.run<any>(
            savePhotosS3,
            photosRightToS3,
            (err: string | null, responses?: FileUploadedS3[]) => {
              if (err !== null) {
                Modal.error({
                  title: "Error",
                  content: err,
                });
                setLoading(false);
                setSaveStep(null);
                return;
              } else if (!!responses) {
                responsesImageRight = responses;
                let photosRightToBeSaved : any ;
                photosRightToBeSaved = {
                  photos_right: babyPage.photosRightSide.map(
                    (photo: SaveBabyPagePhoto, index: number) => ({
                      zoom: calculateZoom(
                        photo,
                        getAspectFromLayout(babyPage.layoutRightSide, index)
                      ),
                      name: responsesImageRight[index + 1].high_res,
                      croppedData: photo.croppedData,
                      origin: calculateOrigin(photo),
                      originPercent: calculateOriginPercent(photo),
                      rotation: !!photo.croppedData
                        ? photo.croppedData.rotate
                        : 0,
                      label_url:
                        index === 0
                          ? !!label_url
                            ? babyPage.label_url_file
                              ? babyPage.label_url_file
                              : responsesImageRight[responsesImageRight.length - 1].high_url
                            : undefined
                          : undefined,
                    })
                  ),
                }
                setPhotosToBeSaved((old) => ({
                  ...old,
                  ...photosRightToBeSaved
                }))
                setDisabled(false); 
              }
        });
      }
       
    } else {
      setLoading(false);
      setSaveStep(null);
    }
    } catch(error) {
      setBabyPage((state) => ({ ...state, label_url }));
      setLoading(false);
      setSaveStep(null);

      Modal.error({
        title: "Error",
        content: error.message,
      });
    }
    setLoading(false);
  
}, [
  isPhotoOnly,
  babyPage,
  control.step,
  photosTobeSaved
])


 const uploadThumbnailsAndSaveBP = useCallback(async () => {
  setLoading(true);
  setSaveStep("genImg");
  const hasEntry = answersArray.filter((a) => a.type === "height_weight" || a.type === "weight" || a.type === "height")

  if(!!hasEntry.length && !isFutureDate) {
    saveGrowthChartEntry();
  }
 

  const border = !!babyPage.borderSelected
    ? lastSegmentUrl(babyPage.borderSelected.image_url)
    : null;
  const illustration = !!babyPage.illustrationSelected
    ? lastSegmentUrl(babyPage.illustrationSelected.image_url)
    : "";
  const background_color = !!babyPage.backgroundSelected
    ? babyPage.backgroundSelected.color
    : "#ffffff";

  let cPhotos: HTMLCanvasElement | null = null;
  let cContent: HTMLCanvasElement | null = null;
  let cAnswers: HTMLCanvasElement | null = null;
  let label_url = !!babyPage.label_url ? babyPage.label_url : undefined;

  try {
    if (!!babyPage.borderSelected) {
      const borderWithBase64 = {
        ...babyPage.borderSelected,
        image_b64: await getBase64FromFileUrl(
          babyPage.borderSelected.image_url,
          `${BASE_URL}api/avoid-cors?url=`
        ),
      };
      setBorder(borderWithBase64);
    }

    if (!!babyPage.illustrationSelected) {
      const illustrationWithBase64 = {
        ...babyPage.illustrationSelected,
        image_b64: await getBase64FromFileUrl(
          babyPage.illustrationSelected.image_url,
          `${BASE_URL}api/avoid-cors?url=`
        ),
      };
      setIllustration(illustrationWithBase64);
    }

    if (!!textAnswers && !label_url) {
      const answersElement = document.getElementById("bp-answers");
      if (!!answersElement) {
        cAnswers = await html2canvas(answersElement, {
          backgroundColor: "transparent",
        });

        if (!!cAnswers) {
          label_url = cAnswers.toDataURL("image/png", {
            backgroundColor: "transparent",
          });
        }
      }
    }

    await delay(300);
    const photoGrid = document.getElementById("photo-grid-bp");
    const content = document.getElementById("content-bp");

    if (!!photoGrid && !!content) {
      cPhotos = await html2canvas(photoGrid, { ...highRes, scale: 1 });

      if (isPhotoOnly) {
        cContent = await html2canvas(content, { ...highRes, scale: 1 });
      } else {
        cContent = await html2canvas(content, {
          ...highResContent,
          scale: 1,
          backgroundColor: !!babyPage.backgroundSelected
            ? babyPage.backgroundSelected.color
            : "#ffffff",
        });
      }
    }
    if (!!cPhotos && !!cContent && !!milestone && !!babySelected) {
      // First send images to S3 and Get URL
      var imageCPhoto = await canvasToBlobPromise(cPhotos);
      var imageCContent = await canvasToBlobPromise(cContent);

      const photosToS3: FileUploadS3[] = [
        {
          image: imageCPhoto,
          folder: "bps",
        },
        {
          image: imageCContent,
          folder: "bps_content",
        },
        ...(babyPage.photos
          .filter((photo) => !!photo.file)
          .map((photo) => ({
            image: photo.file,
            folder: "photos",
            baby_id: babySelected.id,
            gallery_image: photo.isLocal ? null : photo.name?.split('/').slice(-1)[0],
          })) as FileUploadS3[]),
      ];

      const photosRightToS3: FileUploadS3[] = [
        // {
        //   image: imageCPhoto,
        //   folder: "bps",
        // },
        {
          image: imageCContent,
          folder: "bps_content",
        },
        ...(babyPage.photosRightSide
          .filter((photo) => !!photo.file)
          .map((photo) => ({
            image: photo.file,
            folder: "photos",
            baby_id: babySelected.id,
            gallery_image: photo.isLocal ? null : photo.name?.split('/').slice(-1)[0],
          })) as FileUploadS3[]),
      ];
      if (!!label_url && !babyPage.label_url_file) {
        photosToS3.push({
          image: b64toBlob(label_url, "image/png"),
          folder: "photos",
        });
      }
      
      if(triggerPhotoUpdate === true) {
        let babyPageToSave = {}
        sagaMiddleware.run<any>(
          savePhotosS3,
          photosToS3,
          (err: string | null, responses?: FileUploadedS3[]) => {
            if (err !== null) {
              Modal.error({
                title: "Error",
                content: err,
              });
              setLoading(false);
              setSaveStep(null);
              return;
            } else if (!!responses) {
              responsesImageLeft = responses;
              let allPhotos = {};
                let photosLeftToBeSaved : any ;
                photosLeftToBeSaved = {
                  photos: babyPage.photos.map(
                    (photo: SaveBabyPagePhoto, index: number) => ({
                      zoom: calculateZoom(
                        photo,
                        getAspectFromLayout(babyPage.layout, index)
                      ),
                      name: responsesImageLeft[index + 2].high_res,
                      croppedData: photo.croppedData,
                      origin: calculateOrigin(photo),
                      originPercent: calculateOriginPercent(photo),
                      rotation: !!photo.croppedData
                        ? photo.croppedData.rotate
                        : 0,
                      label_url:
                        index === 0
                          ? !!label_url
                            ? babyPage.label_url_file
                              ? babyPage.label_url_file
                              : responsesImageLeft[responsesImageLeft.length - 1].high_url
                            : undefined
                          : undefined,
                    })
                  ),
                }
                allPhotos = {
                  ...photosLeftToBeSaved
                }
              
          if (isPhotoOnly) {
            sagaMiddleware.run<any>(
              savePhotosS3,
              photosRightToS3,
              (err: string | null, responses?: FileUploadedS3[]) => {
                if (err !== null) {
                  Modal.error({
                    title: "Error",
                    content: err,
                  });
                  setLoading(false);
                  setSaveStep(null);
                  return;
                } else if (!!responses) {
                 
                  responsesImageRight = responses;
                  let photosRightToBeSaved : any ;
                  photosRightToBeSaved = {
                    photos_right: babyPage.photosRightSide.map(
                      (photo: SaveBabyPagePhoto, index: number) => ({
                        zoom: calculateZoom(
                          photo,
                          getAspectFromLayout(babyPage.layoutRightSide, index)
                        ),
                        name: responsesImageRight[index + 1].high_res,
                        croppedData: photo.croppedData,
                        origin: calculateOrigin(photo),
                        originPercent: calculateOriginPercent(photo),
                        rotation: !!photo.croppedData
                          ? photo.croppedData.rotate
                          : 0,
                        label_url:
                          index === 0
                            ? !!label_url
                              ? babyPage.label_url_file
                                ? babyPage.label_url_file
                                : responsesImageRight[responsesImageRight.length - 1].high_url
                              : undefined
                            : undefined,
                      })
                    ),
                  }
                  allPhotos = {
                    ...allPhotos,
                    ...photosRightToBeSaved,
                  }
                  babyPageToSave = {
                    ...allPhotos,
                    id: !!babyPage.id ? babyPage.id : undefined,
                    milestone_id: milestone.id,
                    event: milestone.title_name,
                    title: babyPage.label_left_text ? babyPage.label_left_text : 
                    babyPage.label_right_text ? babyPage.label_right_text : "BabyPage" ,
                    subtitle: subTitle,
                    background_color: isPhotoOnly ? null : background_color,
                    font: babyPage.font,                 
                    font_id:babyPage.font_id,
                    font_alignment: babyPage.textAlign,
                    font_size: "15",
                    illustration: isPhotoOnly ? null : illustration,
                    answers: isPhotoOnly ? null : babyPage.answers.map((answer) => ({
                        answerString: answer.answer_string,
                        answers: answer.answer,
                        question_id: answer.question_id,
                        type: answer.type,
                        position: answer.position,
                    })),
                    layout: babyPage.layout,
                    layout_right: babyPage.layoutRightSide,
                    border,
                    child_id: !!repair ? babyPage.child_id : babySelected.id,
                    parent_id: babySelected.baby_parent_id,
                    version: "web",
                    thumbnail: responsesImageLeft[0].thumbnail,
                    thumbnail_content: responsesImageRight[0].thumbnail,
                    label_left_text: babyPage.label_left_text ? babyPage.label_left_text : null ,
                    label_right_text: babyPage.label_right_text ? babyPage.label_right_text : null,
                    label_left_position: babyPage.label_left_text && babyPage.label_left_position ? babyPage.label_left_position : 
                                         babyPage.label_left_text ? "bottom-left" : "no-label",
                    label_right_position: babyPage.label_right_text && babyPage.label_right_position ? babyPage.label_right_position : 
                                          babyPage.label_right_text ? "bottom-left" : "no-label",
                    original_title: milestoneDate,
              }
              setSaveStep("saveBabyPage");
              sagaMiddleware.run<any>(
                saveBabyPageSaga,
                babyPageToSave,
                (error?: any, id?: number) => {
                  setLoading(false);
                  setSaveStep(null);
            
                  if (error) {
                    Modal.error({
                      title: "Error",
                      content: error,
                    });
                    return;
                  }
            
                  trackAction(
                    "SaveBabyPage",
                    {
                      google: {
                        event: "save_baby_page",
                      },
                    },
                    true
                  );
            
                  setBabyPage((old) => ({
                    ...old,
                    somethingChanged: false,
                  }));
            
                  notification.success({
                    message: "Success",
                    description: "BabyPage saved successfully",
                  });
            
                  setTimeout(() => {
                    // Check if show rating form
                    sagaMiddleware.run<any>(checkUserModal, history);
            
                    if (!!id) {
                      history.push(`/babypages/after-save?id=${id}`);
                    } else {
                      history.push("/babypages");
                    }
                  }, 300);
                }
              ); 
                
                }
            });
            //is not photoOnly
          } else {
            babyPageToSave = {
              ...allPhotos,
              id: !!babyPage.id ? babyPage.id : undefined,
                    milestone_id: milestone.id,
                    event: milestone.title_name,
                    title,
                    subtitle: subTitle,
                    background_color: isPhotoOnly ? null : background_color,
                    font: babyPage.font,
                    font_id:babyPage.font_id,
                    font_alignment: babyPage.textAlign,
                    font_size: "15",
                    illustration: isPhotoOnly ? null : illustration,
                    answers: isPhotoOnly ? null : babyPage.answers.map((answer) => ({
                        answerString: answer.answer_string,
                        answers: answer.answer,
                        question_id: answer.question_id,
                        type: answer.type,
                        position: answer.position,
                    })),
                    layout: babyPage.layout,
                    layout_right: babyPage.layoutRightSide,
                    border,
                    child_id: !!repair ? babyPage.child_id : babySelected.id,
                    parent_id: babySelected.baby_parent_id,
                    version: "web",
                    thumbnail: responsesImageLeft[0].thumbnail,
                    thumbnail_content: responsesImageLeft[1].thumbnail,
                    label_left_text: babyPage.label_left_text ? babyPage.label_left_text : null ,
                    label_right_text: babyPage.label_right_text ? babyPage.label_right_text : null,
                    label_left_position: babyPage.label_left_text && babyPage.label_left_position ? babyPage.label_left_position : 
                                         babyPage.label_left_text ? "bottom-left" : "no-label",
                    label_right_position: babyPage.label_right_text && babyPage.label_right_position ? babyPage.label_right_position : 
                                          babyPage.label_right_text ? "bottom-left" : "no-label",
                    original_title: milestoneDate,
                    milestone_date_as_first_line: isCustomOrFirstEvent ? milestoneAsFirstLine : null,                

            }
            setSaveStep("saveBabyPage");
              sagaMiddleware.run<any>(
                saveBabyPageSaga,
                babyPageToSave,
                (error?: any, id?: number) => {
                  setLoading(false);
                  setSaveStep(null);
            
                  if (error) {
                    Modal.error({
                      title: "Error",
                      content: error,
                    });
                    return;
                  }
            
                  trackAction(
                    "SaveBabyPage",
                    {
                      google: {
                        event: "save_baby_page",
                      },
                    },
                    true
                  );
            
                  setBabyPage((old) => ({
                    ...old,
                    somethingChanged: false,
                  }));
            
                  notification.success({
                    message: "Success",
                    description: "BabyPage saved successfully",
                  });
            
                  setTimeout(() => {
                    // Check if show rating form
                    sagaMiddleware.run<any>(checkUserModal, history);
            
                    if (!!id) {
                      history.push(`/babypages/after-save?id=${id}`);
                    } else {
                      history.push("/babypages");
                    }
                  }, 300);
                }
              ); 
          }
        }
      });
        // not update
      } else if (!triggerPhotoUpdate) {
        const photosToS3WithThumbnails: FileUploadS3[] = [
          {
            image: imageCPhoto,
            folder: "bps",
          },
          {
            image: imageCContent,
            folder: "bps_content",
          },
          ...(babyPage.photos
            .filter((photo) => !!photo.file)
            .map((photo) => ({
              image: photo.file,
              folder: "photos",
              baby_id: babySelected.id,
              gallery_image: photo.isLocal ? null : photo.name?.split('/').slice(-1)[0],
            })) as FileUploadS3[]),
        ];

        if(!isPhotoOnly && label_url){
          photosToS3WithThumbnails.push({
            image: b64toBlob(label_url!, "image/png"),
            folder: "photos",
          });
        }
        
        sagaMiddleware.run<any>(
          savePhotosS3,
          photosToS3WithThumbnails,
          (err: string | null, responses?: FileUploadedS3[]) => {
            if (err !== null) {
              Modal.error({
                title: "Error",
                content: err,
              });
              setLoading(false);
              setSaveStep(null);
              return;
            } else if (!!responses) {
              let babyPageToSave = {
                ...photosTobeSaved,
                photos: babyPage.photos.map(
                  (photo: SaveBabyPagePhoto, index: number) => ({
                    zoom: calculateZoom(
                      photo,
                      getAspectFromLayout(babyPage.layout, index)
                    ),
                    name: responses[index + 2].high_res,
                    croppedData: photo.croppedData,
                    origin: calculateOrigin(photo),
                    originPercent: calculateOriginPercent(photo),
                    rotation: !!photo.croppedData
                      ? photo.croppedData.rotate
                      : 0,
                    label_url: responses[responses.length - 1].high_url
                  })
                ),
                id: !!babyPage.id ? babyPage.id : undefined,
                milestone_id: milestone.id,
                event: milestone.title_name,
                title: !isPhotoOnly 
                           ? title 
                           : babyPage.label_left_text 
                           ? babyPage.label_left_text 
                           : babyPage.label_right_text 
                           ? babyPage.label_right_text : "BabyPage" ,
                 subtitle: subTitle,
          background_color: isPhotoOnly ? null : background_color,
          font: babyPage.font,
          font_id: babyPage.font_id,
          font_alignment: babyPage.textAlign,
          font_size: "15",
          illustration: isPhotoOnly ? null : illustration,
          answers: isPhotoOnly ? null : babyPage.answers.map((answer) => ({
            answerString: answer.answer_string,
            answers: answer.answer,
            question_id: answer.question_id,
            type: answer.type,
            position: answer.position,
          })),
          layout: babyPage.layout,
          layout_right: babyPage.layoutRightSide,
          border,
          child_id: !!repair ? babyPage.child_id : babySelected.id,
          parent_id: babySelected.baby_parent_id,
          version: "web",
          thumbnail: responses[0].thumbnail,
          thumbnail_content: responses[1].thumbnail,
          label_left_text: babyPage.label_left_text ? babyPage.label_left_text : null ,
          label_right_text: babyPage.label_right_text ? babyPage.label_right_text : null,
          label_left_position: babyPage.label_left_text && babyPage.label_left_position ? babyPage.label_left_position : 
                               babyPage.label_left_text ? "bottom-left" : "no-label",
          label_right_position: babyPage.label_right_text && babyPage.label_right_position ? babyPage.label_right_position : 
                                babyPage.label_right_text ? "bottom-left" : "no-label",
          original_title: milestoneDate,
          milestone_date_as_first_line: isCustomOrFirstEvent ? milestoneAsFirstLine : null,
          }
          setPhotosToBeSaved((old) => ({
            ...old,
            photos: babyPage.photos.map(
              (photo: SaveBabyPagePhoto, index: number) => ({
                zoom: calculateZoom(
                  photo,
                  getAspectFromLayout(babyPage.layout, index)
                ),
                name: responses[index + 2].high_res,
                croppedData: photo.croppedData,
                origin: calculateOrigin(photo),
                originPercent: calculateOriginPercent(photo),
                rotation: !!photo.croppedData
                  ? photo.croppedData.rotate
                  : 0,
                label_url:
                  index === 0
                    ? !!label_url
                      ? babyPage.label_url_file
                        ? babyPage.label_url_file
                        : responses[responses.length - 1].high_url
                      : undefined
                    : undefined,
              })
            ),
          }))
              setSaveStep("saveBabyPage");
              sagaMiddleware.run<any>(
                saveBabyPageSaga,
                babyPageToSave,
                (error?: any, id?: number) => {
                  setLoading(false);
                  setSaveStep(null);
            
                  if (error) {
                    Modal.error({
                      title: "Error",
                      content: error,
                    });
                    return;
                  }
            
                  trackAction(
                    "SaveBabyPage",
                    {
                      google: {
                        event: "save_baby_page",
                      },
                    },
                    true
                  );
            
                  setBabyPage((old) => ({
                    ...old,
                    somethingChanged: false,
                  }));

                  notification.success({
                    message: "Success",
                    description: "BabyPage saved successfully",
                  });
            
                  setTimeout(() => {
                    // Check if show rating form
                    sagaMiddleware.run<any>(checkUserModal, history);
                  
                    if(!babyPage?.id && !id){
                      history.push("/babypages");
                      return
                    }
                    
                    history.push(`/babypages/after-save?id=${id ?? babyPage.id}`);
                  
                  }, 300);
                }
              ); 
            }
      });
      }   

    } else {
      setLoading(false);
      setSaveStep(null);
    }
  } catch (error) {
    setBabyPage((state) => ({ ...state, label_url }));
    setLoading(false);
    setSaveStep(null);

    Modal.error({
      title: "Error",
      content: error.message,
    });
  }

  
}, [isPhotoOnly,
  babyPage,
  control.step,
  photosTobeSaved, subTitle, milestoneDate])

  // Save BabyPage
  const saveBabyPage = useCallback(async () => {
    setLoading(true);
    setSaveStep("genImg");

    let cPhotos: HTMLCanvasElement | null = null;
    let cContent: HTMLCanvasElement | null = null;
    let cAnswers: HTMLCanvasElement | null = null;
    let label_url = !!babyPage.label_url ? babyPage.label_url : undefined;

    const border = !!babyPage.borderSelected
      ? lastSegmentUrl(babyPage.borderSelected.image_url)
      : null;
    const illustration = !!babyPage.illustrationSelected
      ? lastSegmentUrl(babyPage.illustrationSelected.image_url)
      : "";
    const background_color = !!babyPage.backgroundSelected
      ? babyPage.backgroundSelected.color
      : "#ffffff";

    try {
      if (!!babyPage.borderSelected) {
        const borderWithBase64 = {
          ...babyPage.borderSelected,
          image_b64: await getBase64FromFileUrl(
            babyPage.borderSelected.image_url,
            `${BASE_URL}api/avoid-cors?url=`
          ),
        };
        setBorder(borderWithBase64);
      }

      if (!!babyPage.illustrationSelected) {
        const illustrationWithBase64 = {
          ...babyPage.illustrationSelected,
          image_b64: await getBase64FromFileUrl(
            babyPage.illustrationSelected.image_url,
            `${BASE_URL}api/avoid-cors?url=`
          ),
        };
        setIllustration(illustrationWithBase64);
      }

      if (!!textAnswers && !label_url) {
        const answersElement = document.getElementById("bp-answers");
        if (!!answersElement) {
          cAnswers = await html2canvas(answersElement, {
            backgroundColor: "transparent",
          });

          if (!!cAnswers) {
            label_url = cAnswers.toDataURL("image/png", {
              backgroundColor: "transparent",
            });
          }
        }
      }

      await delay(300);
      const photoGrid = document.getElementById("photo-grid-bp");
      const content = document.getElementById("content-bp");

      if (!!photoGrid && !!content) {
        cPhotos = await html2canvas(photoGrid, { ...highRes, scale: 1 });

        if (isPhotoOnly) {
          cContent = await html2canvas(content, { ...highRes, scale: 1 });
        } else {
          cContent = await html2canvas(content, {
            ...highResContent,
            scale: 1,
            backgroundColor: !!babyPage.backgroundSelected
              ? babyPage.backgroundSelected.color
              : "#ffffff",
          });
        }
      }
      if (!!cPhotos && !!cContent && !!milestone && !!babySelected) {
        // First send images to S3 and Get URL
        var imageCPhoto = await canvasToBlobPromise(cPhotos);
        var imageCContent = await canvasToBlobPromise(cContent);

        // const checkCanvasToBlobPromise = (
        //   cPhotos: HTMLCanvasElement,
        //   cContent: HTMLCanvasElement
        // ) => {
        //   canvasToBlobPromise(cPhotos)
        //     .then(() => {
        //       canvasToBlobPromise(cContent).catch(err => err);
        //     })
        //     .catch(err => err);
        // };

        // checkCanvasToBlobPromise(cPhotos, cContent);
        const photosToS3: FileUploadS3[] = [
          {
            image: imageCPhoto,
            folder: "bps",
          },
          {
            image: imageCContent,
            folder: "bps_content",
          },
          ...(babyPage.photos
            .filter((photo) => !!photo.file)
            .map((photo) => ({
              image: photo.file,
              folder: "photos",
              baby_id: babySelected.id,
              gallery_image: photo.isLocal ? null : photo.name?.split('/').slice(-1)[0],
            })) as FileUploadS3[]),
        ];

        const photosRightToS3: FileUploadS3[] = [
          {
            image: imageCContent,
            folder: "bps",
          },
          {
            image: imageCPhoto,
            folder: "bps_content",
          },
          ...(babyPage.photosRightSide
            .filter((photo) => !!photo.file)
            .map((photo) => ({
              image: photo.file,
              folder: "photos",
              baby_id: babySelected.id,
              gallery_image: photo.isLocal ? null : photo.name?.split('/').slice(-1)[0],
            })) as FileUploadS3[]),
        ];
        if (!!label_url && !babyPage.label_url_file) {
          photosToS3.push({
            image: b64toBlob(label_url, "image/png"),
            folder: "photos",
          });
        }

        setSaveStep("sendImg");
        let responsesImageLeft : any, responsesImageRight : any;

        sagaMiddleware.run<any>(
          savePhotosS3,
          photosToS3,
          (err: string | null, responses?: FileUploadedS3[]) => {
            if (err !== null) {
              setLoading(false);
              setSaveStep(null);
              return;
            } else if (!!responses) {
              responsesImageLeft = responses;
              let babyPageToSave;

              if(isPhotoOnly) {
              sagaMiddleware.run<any>(
                savePhotosS3,
                photosRightToS3,
                (err: string | null, responses?: FileUploadedS3[]) => {
                  if (err !== null) {
                    Modal.error({
                      title: "Error",
                      content: err,
                    });
                    setLoading(false);
                    setSaveStep(null);
                    return;
                  } else if (!!responses) {
                    responsesImageRight = responses;

                    babyPageToSave = {  
                      id: !!babyPage.id ? babyPage.id : undefined,
                      milestone_id: milestone.id,
                      event: milestone.title_name,
                      title: babyPage.label_left_text ? babyPage.label_left_text : 
                             babyPage.label_right_text ? babyPage.label_right_text : "BabyPage" ,
                      subtitle: subTitle,
                      background_color,
                      font: babyPage.font,
                      font_id:babyPage.font_id,
                      font_alignment: babyPage.textAlign,
                      font_size: "15",
                      illustration,
                      answers: babyPage.answers.map((answer) => ({
                        answerString: answer.answer_string,
                        answers: answer.answer,
                        question_id: answer.question_id,
                        type: answer.type,
                        position: answer.position,
                      })),
                      photos: babyPage.photos.map(
                        (photo: SaveBabyPagePhoto, index: number) => ({
                          zoom: calculateZoom(
                            photo,
                            getAspectFromLayout(babyPage.layout, index)
                          ),
                          name: responsesImageLeft[index + 2].high_res,
                          croppedData: photo.croppedData,
                          origin: calculateOrigin(photo),
                          originPercent: calculateOriginPercent(photo),
                          rotation: !!photo.croppedData
                            ? photo.croppedData.rotate
                            : 0,
                          label_url:
                            index === 0
                              ? !!label_url
                                ? babyPage.label_url_file
                                  ? babyPage.label_url_file
                                  : responsesImageLeft[responsesImageLeft.length - 1].high_url
                                : undefined
                              : undefined,
                        })
                      ),
                      photos_right: isPhotoOnly ? babyPage.photosRightSide.map(
                        (photo: SaveBabyPagePhoto, index: number) => ({
                          zoom: calculateZoom(
                            photo,
                            getAspectFromLayout(babyPage.layoutRightSide, index)
                          ),
                          name: responsesImageRight[index + 2].high_res,
                          croppedData: photo.croppedData,
                          origin: calculateOrigin(photo),
                          originPercent: calculateOriginPercent(photo),
                          rotation: !!photo.croppedData
                            ? photo.croppedData.rotate
                            : 0,
                          label_url:
                            index === 0
                              ? !!label_url
                                ? babyPage.label_url_file
                                  ? babyPage.label_url_file
                                  : responsesImageRight[responsesImageRight.length - 1].high_url
                                : undefined
                              : undefined,
                        })
                      ) : null,
                      layout: babyPage.layout,
                      layout_right: babyPage.layoutRightSide,
                      border,
                      child_id: !!repair ? babyPage.child_id : babySelected.id,
                      parent_id: babySelected.baby_parent_id,
                      version: "web",
                      thumbnail: responsesImageLeft[0].thumbnail,
                      thumbnail_content: responsesImageLeft[1].thumbnail,
                      label_left_text: babyPage.label_left_text ? babyPage.label_left_text : null ,
                      label_right_text: babyPage.label_right_text ? babyPage.label_right_text : null,
                      label_left_position: babyPage.label_left_text && babyPage.label_left_position ? babyPage.label_left_position : 
                                           babyPage.label_left_text ? "bottom-left" : "no-label",
                      label_right_position: babyPage.label_right_text && babyPage.label_right_position ? babyPage.label_right_position : 
                                            babyPage.label_right_text ? "bottom-left" : "no-label",
                      original_title: milestoneDate,
                    };
      
                    setSaveStep("saveBabyPage");
                    sagaMiddleware.run<any>(
                      saveBabyPageSaga,
                      babyPageToSave,
                      (error?: any, id?: number) => {
                        setLoading(false);
                        setSaveStep(null);
                  
                        if (error) {
                          Modal.error({
                            title: "Error",
                            content: error,
                          });
                          return;
                        }
                  
                        trackAction(
                          "SaveBabyPage",
                          {
                            google: {
                              event: "save_baby_page",
                            },
                          },
                          true
                        );
                  
                        setBabyPage((old) => ({
                          ...old,
                          somethingChanged: false,
                        }));
                  
                        notification.success({
                          message: "Success",
                          description: "BabyPage saved successfully",
                        });
                  
                        setTimeout(() => {
                          // Check if show rating form
                          sagaMiddleware.run<any>(checkUserModal, history);
                  
                          if (!!id) {
                            history.push(`/babypages/after-save?id=${id}`);
                          } else {
                            history.push("/babypages");
                          }
                        }, 300);
                      }
                    ); 

                  } else {
                    Modal.error({
                      title: "Error",
                      content: "Error saving BabyPage",
                    });
                    setLoading(false);
                    setSaveStep(null);
                  }
                }
               );
              } else {
                babyPageToSave = {  
                  id: !!babyPage.id ? babyPage.id : undefined,
                  milestone_id: milestone.id,
                  event: milestone.title_name,
                  title: title,
                  subtitle: subTitle,
                  background_color,
                  font: babyPage.font,
                  font_id:babyPage.font_id,
                  font_alignment: babyPage.textAlign,
                  font_size: "15",
                  illustration,
                  answers: babyPage.answers.map((answer) => ({
                    answerString: answer.answer_string,
                    answers: answer.answer,
                    question_id: answer.question_id,
                    type: answer.type,
                    position: answer.position,
                  })),
                  photos: babyPage.photos.map(
                    (photo: SaveBabyPagePhoto, index: number) => ({
                      zoom: calculateZoom(
                        photo,
                        getAspectFromLayout(babyPage.layout, index)
                      ),
                      name: responsesImageLeft[index + 2].high_res,
                      croppedData: photo.croppedData,
                      origin: calculateOrigin(photo),
                      originPercent: calculateOriginPercent(photo),
                      rotation: !!photo.croppedData
                        ? photo.croppedData.rotate
                        : 0,
                      label_url:
                        index === 0
                          ? !!label_url
                            ? babyPage.label_url_file
                              ? babyPage.label_url_file
                              : responsesImageLeft[responsesImageLeft.length - 1].high_url
                            : undefined
                          : undefined,
                    })
                  ),
                  layout: babyPage.layout,
                  border,
                  child_id: !!repair ? babyPage.child_id : babySelected.id,
                  parent_id: babySelected.baby_parent_id,
                  version: "web",
                  thumbnail: responses[0].thumbnail,
                  thumbnail_content: responses[1].thumbnail,
                  original_title: milestoneDate,
                };
                setSaveStep("saveBabyPage");

              sagaMiddleware.run<any>(
                saveBabyPageSaga,
                babyPageToSave,
                (error?: any, id?: number) => {
                  setLoading(false);
                  setSaveStep(null);
                  if (error) {
                    Modal.error({
                      title: "Error",
                      content: error,
                    });
                    return;
                  }
                  trackAction(
                    "SaveBabyPage",
                    {
                      google: {
                        event: "save_baby_page",
                      },
                    },
                    true
                  );
            
                  setBabyPage((old) => ({
                    ...old,
                    somethingChanged: false,
                  }));
                  notification.success({
                    message: "Success",
                    description: "BabyPage saved successfully",
                  });
                  setTimeout(() => {
                    // Check if show rating form
                    sagaMiddleware.run<any>(checkUserModal, history);
                    if (!!id) {
                      history.push(`/babypages/after-save?id=${id}`);
                    } else {
                      history.push("/babypages");
                    }
                  }, 300);
                }
              );
             }
            } else {
              Modal.error({
                title: "Error",
                content: "Error saving BabyPage",
              });
              setLoading(false);
              setSaveStep(null);
            }
          }
         );
      } else {
        setLoading(false);
        setSaveStep(null);
      }
    } catch (error) {
      setBabyPage((state) => ({ ...state, label_url }));
      setLoading(false);
      setSaveStep(null);

      Modal.error({
        title: "Error",
        content: error.message,
      });
    }
  }, [
    babyPage,
    milestone,
    babySelected,
    history,
    setBorder,
    setIllustration,
    title,
    textAnswers,
    subTitle,
    isPhotoOnly,
  ]);

  const nextStep = useCallback(() => {
    if (control.step === 4 || (control.step === 2 && isPhotoOnly)) {
      if (isSelectedPlus) {
        setPlusOpen(true);
      } else {
        window.scrollTo(0, 0);
        document.body.style.overflow='hidden'
        uploadThumbnailsAndSaveBP();
      }
    } else {
        window.scrollTo(0, 0);
       if (control.step === 1 && isPhotoOnly) {
        uploadPhotosToS3();
      }
      if (!!contentRef.current) {
        contentRef.current.scrollTo(0, 0);
      }

      let step = control.step + 1;
      if (
        step === 1 &&
        (ageWeeks === 0 || ageWeeks === -1 || questions.length === 0)
      ) {
        step += 1;
      }

      setControl({
        step,
        valid: step !== 0,
      });

      // if (control.step === 0 && isPhotoOnly) {
      //   Modal.info({
      //     title: "Right Page!",
      //     content: `Awesome! you already created the left side of your BabyPage, 
      //     so let's go ahead and select other layout to complete the right side.`,
      //     okText: "Got It",
      //     maskClosable: true,
      //   });
      // }
    }
  }, [
    control.step,
    saveBabyPage,
    questions.length,
    ageWeeks,
    contentRef,
    milestone,
    isPhotoOnly,
  ]);

  // Render SelectPhotos Component
  const renderPhotos = useCallback(() => {
    const props = {
      setPhotos,
      photos: babyPage.photos,
      layout: babyPage.layout,
      isPlus: !!user.user_level && user.user_level === 1,
      showPlus: () => setPlusOpen(true),
      title: milestone?.age_weeks === -2 ? "Left Page" : undefined,
      step: control.step,
      selectedPlus,
      setSelectedPlus,
    };
    return <SelectPhotos {...props} />;
  }, [setPhotos, babyPage, user.user_level, milestone]);

  // Render Questions Component
  const renderQuestions = useCallback(() => {
    const props = {
      questions: cleanedQuestions,
      heightQuestions,
      setQuestions, // To add custom answers
      answers: babyPage.answers,
      setAnswers,
      nextStep,
      milestone,
      setMilestoneDate,
      setEntry,
      customAnswer,
      setCustomAnswer,
      qIndex,
      setQIndex,
      hasQuestions,
      answer,
      checked,
      changeAnswer,
      addQuestion
    };
    return <Questions {...props} />;
  }, [
    cleanedQuestions,
    heightQuestions,
    babyPage.answers,
    setAnswers,
    nextStep,
    milestone,
    qIndex,
    customAnswer
  ]);

  // Render EditBorderIllustration Component
  const renderBorder = useCallback(() => {
    const props = {
      borderSelected: babyPage.borderSelected,
      illustrationSelected: babyPage.illustrationSelected,
      setBorder,
      setIllustration,
      borders,
      backgroundSelected : babyPage.backgroundSelected,
      illustrations,
      birthDate: title,
      subTitle,
      textAnswers: textAnswers,
      font: babyPage.font,
      textAlign: babyPage.textAlign,
      isPlus: !!user.user_level && user.user_level === 1,
      showPlus: () => setPlusOpen(true),
      isTruncated,
      setIsTruncated,
      answersArray,
      setSortedAnswers,
      setCustomAnswers,
      addCustomAnswer,
      removeAnswer,
      setTitle,
      setSubTitle,
      labelUrl: babyPage.thumbnail_content,
      milestoneType: milestone,
      selectedPlus,
      setSelectedPlus,
      milestoneDate,
      setMilestoneDate,
      setMilestoneAsFirstLine,
      milestoneAsFirstLine,
    };
    return <EditBorderIllustration {...props} />;
  }, [
    babyPage,
    textAnswers,
    setBorder,
    setIllustration,
    borders,
    illustrations,
    user.user_level,
    isTruncated,
    title,
    subTitle,
    answersArray,
    setSortedAnswers,
    setCustomAnswers,
    addCustomAnswer,
    removeAnswer,
    milestone,
    selectedPlus,
    setSelectedPlus,
  ]);

  // Render EditTextBackground Component
  const renderTextBG = useCallback(() => {
    const props = {
      baby: babySelected,
      borderSelected: babyPage.borderSelected,
      backgroundSelected: babyPage.backgroundSelected,
      illustrationSelected: babyPage.illustrationSelected,
      birthDate: title,
      subTitle,
      textAnswers,
      font: babyPage.font,
      textAlign: babyPage.textAlign,
      setFont,
      setTextAlign,
      setBackground,
      isPlus: !!user.user_level && user.user_level === 1,
      showPlus: () => setPlusOpen(true),
      isTruncated,
      setIsTruncated,
      answersArray,
      setSortedAnswers,
      setCustomAnswers,
      addCustomAnswer,
      removeAnswer,
      setTitle,
      setSubTitle,
      labelUrl: babyPage.thumbnail_content,
      milestoneType: milestone,
      selectedPlus,
      setSelectedPlus,
      milestoneDate,
      setMilestoneDate,
      setMilestoneAsFirstLine,
      milestoneAsFirstLine,
    };
    return <EditTextBackground {...props} />;
  }, [
    babySelected,
    babyPage,
    textAnswers,
    setFont,
    setTextAlign,
    setBackground,
    user.user_level,
    isTruncated,
    title,
    subTitle,
    answersArray,
    setSortedAnswers,
    setCustomAnswers,
    addCustomAnswer,
    removeAnswer,
    milestone,
  ]);

  const renderFinish = useCallback(() => {
    const props = {
      birthDate: title,
      subTitle,
      font: babyPage.font,
      textAlign: babyPage.textAlign,
      backgroundSelected: babyPage.backgroundSelected,
      borderSelected: babyPage.borderSelected,
      illustrationSelected: babyPage.illustrationSelected,
      textAnswers,
      setPhotos,
      photos: babyPage.photos,
      layout: babyPage.layout,
      babyPageId: babyPage.id,
      isTruncated,
      setIsTruncated,
      loading,
      answersArray,
      setSortedAnswers,
      setCustomAnswers,
      addCustomAnswer,
      removeAnswer,
      setTitle,
      setSubTitle,
      ageWeeks,
      labelUrl: babyPage.thumbnail_content,
      milestoneType: milestone,
      updateBPStep: setControl,
      photosRightSide: babyPage.photosRightSide,
      layoutRightSide: babyPage.layoutRightSide,
      setBabyPage,
      babyPage,
      setTriggerPhotoUpdate,
      step: control.step,
      setMilestoneDate,
      milestoneDate
    };
    return <Finish {...props} />;
  }, [
    babyPage,
    textAnswers,
    setPhotos,
    loading,
    isTruncated,
    title,
    subTitle,
    answersArray,
    setSortedAnswers,
    setCustomAnswers,
    addCustomAnswer,
    removeAnswer,
    ageWeeks,
    milestone,
  ]);

  const renderPhotosRightSide = useCallback(() => {
    const props = {
      setPhotos,
      photos: babyPage.photosRightSide,
      layout: babyPage.layoutRightSide,
      isPlus: !!user.user_level && user.user_level === 1,
      showPlus: () => setPlusOpen(true),
      title: "Right Page",
      step: control.step,
      selectedPlus,
      setSelectedPlus,
    };
    return <SelectPhotos {...props} />;
  }, [setPhotos, babyPage, user.user_level]);

  // Check what step should be rendered
  const renderStep = useCallback(() => {
    if (milestone?.age_weeks === -2) {
      switch (control.step) {
        case 0:
          return renderPhotos();
        case 1:
          return renderPhotosRightSide();
        default:
          return renderFinish();
      }
    } else {
      switch (control.step) {
        case 0:
          return renderPhotos();
        case 1:
          return renderQuestions();
        case 2:
          return renderBorder();
        case 3:
          return renderTextBG();
        default:
          return renderFinish();
      }
    }
  }, [
    control.step,
    renderPhotos,
    renderQuestions,
    renderBorder,
    renderTextBG,
    renderFinish,
    milestone,
  ]);

  return (
    <BaseLayout
      title="BabyPage - Save BabyPages"
      pageTitle={
        control.step === 0
          ? "Select Photos"
          : control.step === 1 && !isPhotoOnly
          ? "Answer Questions"
          : control.step === 1 && isPhotoOnly
          ? "Select Photos"
          : control.step === 2 && !isPhotoOnly
          ? "Border/Illustration"
          : control.step === 2 && isPhotoOnly
          ? "Label Titles"
          : control.step === 3
          ? "Text/Background"
          : "Save BabyPages"
      }
      hideChildMenu
      hideHeaderChildMenu
      hideBanner
      hideFooter
      pageStyle={{ position: "unset", minHeight: "unset" }}
      contentStyle={{ padding: 0 }}
    >
      <RouteLeavingGuard
        when={babyPage.somethingChanged && !plusOpen}
        navigate={(path) => history.push(path)}
      />
      {loading && (
        <div className="d-flex flex-column justify-content-center align-items-center loading-fullscreen">
          <Icon type="loading" style={{ fontSize: 36 }} />
          {!!saveStep && (
            <p className="mt-3">
              {saveStep === "genImg"
                ? "Generating High Res. Images..."
                : saveStep === "sendImg"
                ? "Saving High Res. Images..."
                : "Saving BabyPage..."}
            </p>
          )}
        </div>
      )}
      <div
        id="save-content"
        className={`${loading ? "save-babypage-hide-scroll" : 
        isPhotoOnly && (control.step === 0 || control.step === 1) ? "layout-hide-bottom save-babypage-content ignore-images" : 
        control.step === 0 ? "layout-hide-bottom save-babypage-content ignore-images" : "save-babypage-content ignore-images"}`}
        ref={contentRef}
      >
        <div
          className={`h-100 ${
            loading || control.step === 4 ? "w-100" : "container"
          }`}
        >
          {renderStep()}
        </div>
      </div>
      <div className="button-bar-margin"></div>
      <div className="button-bar-babypage">
        <div className="row justify-content-center align-items-center">
          {control.step > 0 && (
            <>
              <Button
                type="primary"
                ghost
                className="px-4"
                shape="round"
                disabled={babyPage.isEdit && babyPage.hasMissing ? true  : loading}
                onClick={() => {
                  window.scrollTo(0, 0);
                  if (!!contentRef.current) {
                    contentRef.current.scrollTo(0, 0);
                  }

                  setControl((old) => {
                    let step = old.step - 1;
                    const isPhotoOnlyEdit = old.step === 4 && isPhotoOnly;
                    if (
                      step === 1 &&
                      (ageWeeks === 0 ||
                        ageWeeks === -1 ||
                        questions.length === 0)
                    ) {
                      step -= 1;
                    }
                   
                    if (isPhotoOnlyEdit){
                      step -= 2;
                    }

                    return { step, valid: true };
                  });
                }}
              >
                PREVIOUS
              </Button>
              &nbsp;
            </>
          )}
          <Button
            type="primary"
            className="px-4"
            ghost
            shape="round"
            onClick={() => {
              if(!isPhotoOnly && control.step === 1) {
                addQuestion(questions[qIndex]);
              }
              nextStep();
            }}
             disabled={ control.step === 4 || (isPhotoOnly && control.step === 2) ? disabled :
              !control.valid || loading || (control.step > 1 && isTruncated)
            }

          >
            {(disabled && control.step === 2 && isPhotoOnly) || (disabled && control.step === 4 && !isPhotoOnly) ? "Uploading Photos.." : getTextMessageByStep()}
          </Button>
          <div id="testing-empty-canvas"></div>
        </div>
      </div>
      <ModalPlus
        visible={plusOpen && !isPhotoOnly}
        onClose={() => {
          setPlusOpen(false);
        }}
        onSaveFreeFeatures={saveFreeFeatures}
        onSaveBeforeUpgradeToPlus={saveBeforeUpgradeToPlus}
        savingBabyPage={true}
      />
       <ModalPlus
        visible={plusOpen && isPhotoOnly}
        onClose={() => {
          setPlusOpen(false);

          if (milestone?.age_weeks === -2) {
            history.push("/milestones");
          }
        }}
        onSaveBeforeUpgradeToPlus={sendToUpgradeToPlus}
 />


    {!isPhotoOnly ? (
      <Modal 
      visible={freeUserModal}
      onCancel={() => {
        setFreeUserModal(false)
      }} 
      footer={null}
     >
        <div className="row justify-content-center align-items-center">
          <h1 className="title my-4">As a free user, you will NOT be able to save any PLUS options selected.</h1>
          <div  className="row justify-content-center align-items-center" style={{ padding: 20 }}>
              <Button
                type="primary"
                className="mx-2"
                shape="round"
                onClick={sendToUpgradeToPlus}
              >
                Upgrade to PLUS
              </Button>
    
                <Button
                  type="primary"
                  className="mx-2"
                  shape="round"
                  onClick={() => {
                    setFreeUserModal(false)
                  }}
                >
                  Continue
                </Button>
          </div>
        </div>
     </Modal>
      ) : null
    }
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  milestones: state.milestones.milestones,
  babySelected: state.baby.babySelected,
  borders: state.bpHelper.borders,
  illustrations: state.bpHelper.illustrations,
  baby: state.baby,
  draftBabyPage: state.bpHelper.draftBabyPage,
});

export default connect(mapStateToProps)(SaveBabyPagesPage);
